import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import MuiAlert from '@mui/material/Alert';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Snackbar,
  SnackbarCloseReason,
  Alert,
  TextField,
} from "@mui/material";
import NumberInput from "../../components/configuration/NumberInput";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../../assets/style.css";
import ConfigLoad from "../../components/shared/config/ConfigLoad";
import ConfigBottomBar from "../../components/shared/config/ConfigBottomBar";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import EncompassService from "../../services/encompassService";
import { IConfiguration, IMessageType } from "../../models/configuration/pushback/IConfiguration";
import {
  ReceivedDate_Population_Items,
  Minutes_Min_Default_Value,
  Minutes_Max_Default_Value,
  Concurrency_Min_Default_Value,
  Concurrency_Max_Default_Value,
  Attempts_Min_Default_Value,
  Attempts_Max_Default_Value,
  Get_Minutes_Pushback_DefaultValue,
  Get_Attempts_Pushback_DefaultValue,
  Get_Concurrency_Pushback_DefaultValue,
  Get_Seconds_Pushback_DefaultValue,
  GetPushbackFieldMappings,
  DefaultPushbackData,
  GetPushbackFieldNames
} from "../../constants/PushbackConstants";
import { AlertSeverity } from "../../constants/AlertTypes";

const ENCOMPASS_REQUEST_API_URI = process.env.REACT_APP_DT_REQUEST_API_URI;
const ENCOMPASS_PLUGIN_API_URI = process.env.REACT_APP_DT_PLUGIN_API_URI;
const ENCOMPASS_TOKEN_ENDPOINT_API_URI =
  process.env.REACT_APP_DT_TOKEN_ENDPOINT_API_URI;

const ConfigPushback: React.FC = () => {
  //
  const { getConfigPushbackData, saveConfigPushbackData } =
    useActions();
  const { loading, data } = useTypedSelector(
    (state) => state.configPushbackDefaults
  );
  const [pushbackDefaultData, setPushbackDefaultData] =
    useState<IConfiguration | null>(data?.Configuration || null);
  const accessToken = useTypedSelector((state) => state.appSlice.accessToken);
  // Pushback state
  const [selectedConfigId, setSelectedConfigId] = useState<string>("");
  const [requestUri, setRequestUri] = useState("");
  const [pluginUri, setPluginUri] = useState("");
  const [tokenUri, setTokenUri] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<IMessageType>({ Type: "", Message: [] });
  const pushBackFieldList = GetPushbackFieldMappings(DefaultPushbackData);
  const pushBackFieldNameList = GetPushbackFieldNames(DefaultPushbackData);
  const [alertSeverity, setAlertMessageSeverity] = useState<AlertSeverity>('info');
  const configId = EncompassService.getConfigId() as string;

  const hasFetchedData = useRef(false);

  const fetchPushbackData = useCallback(
    (configId: string) => {
      const result: any = getConfigPushbackData(configId, accessToken);
      result.catch((rejectMsg: IMessageType) => {
        handleAlert(rejectMsg, true, 'error')
      });
    },
    [getConfigPushbackData, accessToken]
  );

  const handleAlert = (message: IMessageType, open: boolean, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
    setAlertMessage(message);
    setAlertOpen(open);
    setAlertMessageSeverity(severity);
  }

  // Save Page Data
  const savePushbackConfigData = useCallback(
    async (configId: string, data: IConfiguration) => {
      return await saveConfigPushbackData(configId, data, accessToken);
    },
    [saveConfigPushbackData, accessToken]
  );

  useEffect(() => {
    if (!hasFetchedData.current && !data) {
      setSelectedConfigId(configId);
      const delayFetch = setTimeout(() => {
        fetchPushbackData(configId);
        initializeScreenData();
        hasFetchedData.current = true;
      }, 500);
      return () => clearTimeout(delayFetch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle Data
  useEffect(() => {
    if (data != null) {
      setPushbackDefaultData(data?.Configuration); // Only update local data if there is no save Error condition
      initializeScreenData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.Configuration]);

  const updatePushbackData = (key: string, value: any) => {
    setPushbackDefaultData((prevData) => {
      if (!prevData) return null;
      return {
        ...prevData,
        [key]: value,
      };
    });
  };

  // Toast
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  function cacheEndpoints() {
    localStorage.setItem("requestUri", requestUri);
    localStorage.setItem("pluginUri", pluginUri);
    localStorage.setItem("tokenUri", tokenUri);
  }

  function initializeScreenData() {
    setRequestUri(
      localStorage.getItem("requestUri") ||
      (ENCOMPASS_REQUEST_API_URI as string)
    );
    setPluginUri(
      localStorage.getItem("pluginUri") || (ENCOMPASS_PLUGIN_API_URI as string)
    );
    setTokenUri(
      localStorage.getItem("tokenUri") ||
      (ENCOMPASS_TOKEN_ENDPOINT_API_URI as string)
    );
    if (!selectedConfigId) {
      setSelectedConfigId(getConfigId());
    }
  }

  const getConfigId = () => {
    if (selectedConfigId) return selectedConfigId;
    return EncompassService.getConfigId() as string;
  };

  const save = async () => {
    cacheEndpoints();
    try {
      var result: any = await savePushbackConfigData(
        getConfigId(),
        pushbackDefaultData as IConfiguration
      );
      handleAlert(result, true, 'success');
    } catch (rejectMsg: any) {
      handleAlert(rejectMsg, true, 'error');
    }
  };

  return (
    <section>
      <div>
        {loading && (
          <ConfigLoad loading={loading} />
        )}
      </div>
      <Box
        mt={1}
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          overflow: "auto",
          minHeight: "100vh",
          margin: "2px",
        }}
      >
        <Card sx={{ margin: "10px" }}>
          <CardContent>
            <Typography sx= {{marginBottom: "20px"}} variant="h5" component="div">
              Pushback Configuration
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <Typography>
                  {pushBackFieldList?.ReceivedDatePopulation}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Select
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={pushbackDefaultData?.ReceivedDatePopulation || ""}
                  onChange={(event) =>
                    updatePushbackData(
                      pushBackFieldNameList.ReceivedDatePopulation,
                      event.target.value
                    )
                  }
                >
                  {ReceivedDate_Population_Items.map((item, index) => (
                    <MenuItem
                      sx={{ padding: "2px 8px" }}
                      key={index}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6}>
                <Typography>{pushBackFieldList?.MaxConcurrency}</Typography>
              </Grid>
              <Grid item xs={6}>
                <NumberInput
                  value={
                    pushbackDefaultData?.MaxConcurrency
                      ? Get_Concurrency_Pushback_DefaultValue(
                        pushbackDefaultData?.MaxConcurrency
                      )
                      : null
                  }
                  onChange={(event: any) => {
                    updatePushbackData(
                      pushBackFieldNameList.MaxConcurrency,
                      event.target.value
                    );
                  }}
                  min={Concurrency_Min_Default_Value}
                  max={Concurrency_Max_Default_Value}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {pushBackFieldList?.MessageRetryVisibilityTimeoutSeconds}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <NumberInput
                  value={
                    pushbackDefaultData?.MessageRetryVisibilityTimeoutSeconds
                      ? Get_Minutes_Pushback_DefaultValue(
                        pushbackDefaultData?.MessageRetryVisibilityTimeoutSeconds
                      )
                      : ""
                  }
                  onChange={(event) => {
                    const val = event.target.value;
                    updatePushbackData(
                      pushBackFieldNameList.MessageRetryVisibilityTimeoutSeconds,
                      val ? Get_Seconds_Pushback_DefaultValue(val) : val
                    );
                  }}
                  min={Minutes_Min_Default_Value}
                  max={Minutes_Max_Default_Value}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {pushBackFieldList?.LoanLockedVisibilityTimeoutSeconds}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <NumberInput
                  value={
                    pushbackDefaultData?.LoanLockedVisibilityTimeoutSeconds
                      ? Get_Minutes_Pushback_DefaultValue(
                        pushbackDefaultData?.LoanLockedVisibilityTimeoutSeconds
                      )
                      : ""
                  }
                  onChange={(event) => {
                    const val = event.target.value;
                    updatePushbackData(
                      pushBackFieldNameList.LoanLockedVisibilityTimeoutSeconds,
                      val ? Get_Seconds_Pushback_DefaultValue(val) : val
                    );
                  }}
                  min={Minutes_Min_Default_Value}
                  max={Minutes_Max_Default_Value}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {pushBackFieldList?.UnnamedDocumentTitle}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={pushbackDefaultData?.UnnamedDocumentTitle}
                  onChange={(e) =>
                    updatePushbackData(
                      pushBackFieldNameList.UnnamedDocumentTitle,
                      e.target.value
                    )
                  }
                  sx={{ flex: 1 }}
                  fullWidth
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {pushBackFieldList?.MaxMessageRetryCount}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <NumberInput
                  value={
                    pushbackDefaultData?.MaxMessageRetryCount ===
                      Attempts_Min_Default_Value ||
                      pushbackDefaultData?.MaxMessageRetryCount
                      ? Get_Attempts_Pushback_DefaultValue(
                        pushbackDefaultData?.MaxMessageRetryCount
                      )
                      : null
                  }
                  onChange={(event: any) => {
                    updatePushbackData(
                      pushBackFieldNameList.MaxMessageRetryCount,
                      event.target.value
                    );
                  }}
                  min={Attempts_Min_Default_Value}
                  max={Attempts_Max_Default_Value}
                />
              </Grid>
            </Grid>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={alertOpen}
              autoHideDuration={4000}
              onClose={handleClose}
            >
              <MuiAlert
                onClose={handleClose}
                severity={alertSeverity}
                sx={{ width: '100%', fontSize: '1.2rem', padding: '12px 16px' }}>
                <div>
                  {alertMessage?.Message?.[0]}
                  {alertMessage?.Message?.length > 1 &&
                    <ul>
                      {alertMessage?.Message?.filter?.(
                        (val, index) => index !== 0 && val
                      ).map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  }
                </div>
              </MuiAlert>
            </Snackbar>
          </CardContent>
        </Card>
        <ConfigBottomBar save={save} />
      </Box>
    </section>
  );
};

export default ConfigPushback;