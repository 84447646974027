import React, { useState, useEffect } from "react";
import { Box, Card, Stack, Select, InputLabel, FormControl, MenuItem, FormControlLabel, FormGroup, TextField, Checkbox, SelectChangeEvent, CardContent, CardActions, Button, Typography, IconButton, Snackbar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import GenericDialog from "../../../components/shared/GenericDialog";
import { IDocumentBundle, IDocumentHandlingPackageConfig, IMergerPackageConfig } from "../../../models/configuration/bundles/IBundles";
import DTDataGrid from "../../../components/shared/config/DTDataGrid";
import { useDocumentMappings } from "../../../hooks/useDocumentMappings";
import { mergePackageConfigColumns, packageConfigColumns } from "./configbundlingdata";
import { DocumentMapsPrefixType } from "../../../enums/BundlingTypes";
import MuiAlert from '@mui/material/Alert';
import { AlertSeverity } from "../../../constants/AlertTypes";

interface ConfigDocumentHandlingDialogProps {
    open: boolean;
    documentBundle: IDocumentBundle;
    updateBundle: (bundle: IDocumentBundle) => void;
    closeFxtn: () => void;
}

interface IBundlingValidator {
    error: boolean;
    raise: boolean;
    message: string;
    title: string;
}

const OneFolderPerDocument = "OneFolderPerDocument";
const OneFolderPerPackage = "OneFolderPerPackage";
const TitleOneFolderPerDocument = "Document Handling Package Configs";
const TitleOneFolderPerPackage = "Merger Package Configs";

const ConfigDocumentHandlingDialog: React.FC<ConfigDocumentHandlingDialogProps> = ({ open, documentBundle, updateBundle, closeFxtn }) => {

    const [documentBundleState, setDocumentBundleState] = useState<IDocumentBundle>(documentBundle);
    const [selectedRow, setSelectedRow] = useState<number | null>(null);
    const [title, setTitle] = useState<string>(TitleOneFolderPerDocument);

    // State Confirmations
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState<AlertSeverity>('info');

    const documentMappings = useDocumentMappings();
    const documentMapsPrefix = [DocumentMapsPrefixType.ALL, DocumentMapsPrefixType.AUDIT_LOG];

    useEffect(() => {
        setDocumentBundleState(documentBundle);
    }, [documentBundle]);

    const handleSelectChange = (e: SelectChangeEvent) => {
        const { name, value } = e.target;
        setTitle(value === OneFolderPerPackage ? TitleOneFolderPerPackage : TitleOneFolderPerDocument);
        handlePropChange(name, value);
    };

    const handlePropChange = (name: string, value: any) => {
        setDocumentBundleState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // * Handling DocumentHandlingPackageConfigs

    const handleItemSelectChangeDocumentHandlingPackageConfig = (
        e: SelectChangeEvent,
        item: IDocumentHandlingPackageConfig
    ) => {
        const { name, value } = e.target;
        handleItemPropChangeDocumentHandlingPackageConfig(name, value, item);
    };

    const handleItemCheckBoxChangeDocumentHandlingPackageConfig = (
        e: React.ChangeEvent<HTMLInputElement>,
        item: IDocumentHandlingPackageConfig
    ) => {
        const { name, checked } = e.target;
        handleItemPropChangeDocumentHandlingPackageConfig(name, checked, item);
    };

    const handleItemInputChangeDocumentHandlingPackageConfig = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        item: IDocumentHandlingPackageConfig
    ) => {
        const { name, value } = e.target;
        handleItemPropChangeDocumentHandlingPackageConfig(name, value, item);
    };

    const handleItemPropChangeDocumentHandlingPackageConfig = (
        name: string,
        value: any,
        item: IDocumentHandlingPackageConfig
    ) => {
        const documentIndexStr = "DocumentIndex";
        // find new document map value 
        let map = documentMappings?.Configuration.DocumentMaps.find((map) => map.DocumentIndex === value);
        //set document names empty for documentMapsPrefix
        if (value && value === DocumentMapsPrefixType.AUDIT_LOG || value === DocumentMapsPrefixType.ALL) {
        map = {
          DocumentIndex: value,
          ConformXDocumentName: "",
          EncompassDocumentName: "",
        }
      }
        const docIndexChange = value !== item.DocumentIndex; // drop down change 
        (item as any)[name] = name === documentIndexStr ? value.toString() : value;
        setDocumentBundleState((prevState) => {
            const updatedConfigs = prevState.DocumentHandlingPackageConfigs.map((config) => {
                if (docIndexChange && map && config.DocumentIndex === item.DocumentIndex) {
                    return {
                        ...config,
                        DocumentName: map.ConformXDocumentName,
                        EncompassFolder: map.EncompassDocumentName,
                    };
                }
                return config;
            });
            return {
                ...prevState,
                DocumentHandlingPackageConfigs: updatedConfigs,
            };
        });
    };

    // * Handling MergerPackageConfigs

    const handleItemSelectChangeMergerPackageConfig = (
        e: SelectChangeEvent,
        item: IMergerPackageConfig
    ) => {
        const { name, value } = e.target;
        handleItemPropChangeMergerPackageConfig(name, value, item);
    };

    const handleItemCheckBoxChangeMergerPackageConfig = (
        e: React.ChangeEvent<HTMLInputElement>,
        item: IMergerPackageConfig
    ) => {
        const { name, checked } = e.target;
        handleItemPropChangeMergerPackageConfig(name, checked, item);
    };

    const handleItemInputChangeMergerPackageConfig = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        item: IMergerPackageConfig
    ) => {
        const { name, value } = e.target;
        handleItemPropChangeMergerPackageConfig(name, value, item);
    };

    const handleItemPropChangeMergerPackageConfig = (
        name: string,
        value: any,
        item: IMergerPackageConfig
    ) => {
        //  to force an array on the mergeList 
        const mergeListStr = "MergeList";
        (item as any)[name] = name === mergeListStr ? value.split(",") : value;
        setDocumentBundleState((prevState) => ({
            ...prevState,
        }));
    };

    const addRow = () => {
        if (documentBundleState?.BundlingOption === OneFolderPerDocument) {
            const bundleItemsDHPCs = [
                ...documentBundleState?.DocumentHandlingPackageConfigs,
            ];
            const blankItemDHPC: IDocumentHandlingPackageConfig = {
                DocumentIndex: null,
                DocumentName: "",
                EncompassFolder: "",
                Suffix: "",
                ReuseFolder: false,
                EncompassAttachment: "",
            };

            bundleItemsDHPCs.push(blankItemDHPC);

            setDocumentBundleState((prevState) => ({
                ...prevState,
                DocumentHandlingPackageConfigs: bundleItemsDHPCs,
            }));
        }
        if (documentBundleState?.BundlingOption === OneFolderPerPackage) {
            const bundleItemMPCs = [
                ...documentBundleState?.MergerPackageConfigs
            ];
            const blankItemMPCs: IMergerPackageConfig = {
                MergeList: [],
                EncompassFolder: "",
                Suffix: "",
                ReuseFolder: false,
                EncompassAttachment: "",
            };

            bundleItemMPCs.push(blankItemMPCs);

            setDocumentBundleState((prevState) => ({
                ...prevState,
                MergerPackageConfigs: bundleItemMPCs,
            }));
        }
    };
    const removeRow = () => {
        if (selectedRow === null) return;
        if (documentBundleState?.BundlingOption === OneFolderPerDocument) {
            const updatedConfigs = documentBundleState.DocumentHandlingPackageConfigs.filter(
                (_, index) => index !== selectedRow
            );
            setDocumentBundleState((prevState) => ({
                ...prevState,
                DocumentHandlingPackageConfigs: updatedConfigs,
            }));
        } else if (documentBundleState?.BundlingOption === OneFolderPerPackage) {
            const updatedConfigs = documentBundleState.MergerPackageConfigs.filter(
                (_, index) => index !== selectedRow
            );
            setDocumentBundleState((prevState) => ({
                ...prevState,
                MergerPackageConfigs: updatedConfigs,
            }));
        }
        setSelectedRow(null);
    };

    const handleRowClick = (rowIndex: number) => {
        setSelectedRow(rowIndex);
    };

    const closeScreen = () => {
        if (documentBundleState != null) {
            const validation = documentBundleStateValidated(documentBundleState);
            if (validation.error) {
                if (validation.raise) {
                    openAlert(validation.message, 'error');
                } else {
                    closeFxtn();
                }
                return;
            }
            updateBundle(documentBundleState);
            closeFxtn();
        }
    }

    // Validation Functions

    const createDefaultBundlingValidator = (message: string): IBundlingValidator =>
    ({
        error: false,
        raise: false,
        message: message || "",
        title: ""
    });

    const documentBundleStateValidated = (bundle: IDocumentBundle): IBundlingValidator => {
        const validator = createDefaultBundlingValidator("");

        const validateDHPC = validateDocumentHandlingPackageConfigs(bundle.DocumentHandlingPackageConfigs);

        if (validateDHPC.error) {
            return { ...validator, ...validateDHPC };
        }

        const validateMPC = validateMergerPackageConfigs(bundle.MergerPackageConfigs);
        if (validateMPC.error) {
            return { ...validator, ...validateMPC };
        }

        return validator;
    }

    const validateDocumentHandlingPackageConfigs = (configs: IDocumentHandlingPackageConfig[])
        : IBundlingValidator => {

        let validator = createDefaultBundlingValidator("Invalid Document Handling Package");
        if (!configs || configs.length === 0) return validator;
        configs.forEach((config) => {
            if (config.DocumentIndex === null) {
                setDocumentBundleState((prevState) => ({
                    ...prevState,
                    DocumentHandlingPackageConfigs: configs.filter((c) => c !== config)
                }));
                validator.error = true;
                validator.message = "Document Index is Mandatory";
                return validator;
            }
        });
        return validator;
    }

    const validateMergerPackageConfigs = (configs: IMergerPackageConfig[])
        : IBundlingValidator => {

        let validator = createDefaultBundlingValidator("Invalid Merger Package");
        if (!configs || configs.length === 0) return validator;
        configs.forEach((config) => {
            // Remove config item if Merge list check for null or undefined and if any items have no values 
            if (!config.MergeList?.length || config.MergeList.some(item => !item.length)) {
                setDocumentBundleState((prevState) => ({
                    ...prevState,
                    MergerPackageConfigs: configs.filter((c) => c !== config)
                }));
                validator.error = true;
                validator.message = "Merge List is Mandatory";
                return validator;
            }

            // Empty Folder check
            if ((config.MergeList ?? []).length > 0 && config.EncompassFolder === "") {
                validator.error = true;
                validator.raise = true;
                validator.message = "Encompass Folder is Mandatory";
                return validator;
            }
        });

        return validator
    }

    // Alert Handling 
    const handleSnackBarClose = (_event: any) => { setAlertOpen(false); }
    const openAlert = (message: string, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
        setAlertSeverity(severity);
        setAlertMessage(message);
        setAlertOpen(true);
    }

    if (documentBundleState == null) return <></>;

    if (documentMappings == null) return <></>;

    return (
        
      <GenericDialog
        open={open}
        title={"Document Handling Configuration"}
        onClose={closeScreen}
        cancelButtonText="Close"
      >
        <IconButton
          aria-label="close"
          onClick={closeScreen}
          sx={{
                    position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            height: "100%",
          }}
        >
          <Stack direction="row" justifyContent="start" spacing={1}>
            <Box sx={{ flex: 1 }} className={["flexLeft"].join(" ")}>
              <TextField
                sx={{ marginTop: "10px" }}
                label="Package ID"
                name="PackageID"
                value={documentBundleState?.PackageId}
                fullWidth
                variant="outlined"
              />
            </Box>
            
            {documentBundleState?.BundlingOption === OneFolderPerDocument ? (
              <Box sx={{ flex: 1 , padding: "14px" }} className={["flexLeft"].join(" ")}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ marginLeft: "21px" }}
                        checked={documentBundleState?.DocumentHandlingDuplicate ?? false}
                        onChange={(e) =>
                          handlePropChange(
                            "DocumentHandlingDuplicate",
                            e.target.checked
                          )
                        }
                      />
                    }
                    label="Allow Document Handling Duplication"
                  />
                </FormGroup>
              </Box>
            ) : (
              <></>
            )}

            {documentBundleState?.BundlingOption === OneFolderPerPackage ? (
              <Box sx={{ flex: 1, padding: "14px" }} className={["flexLeft"].join(" ")}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ marginLeft: "21px" }}
                        checked={documentBundleState?.MergerConfigDuplicate ?? false}
                        onChange={(e) =>
                          handlePropChange(
                            "MergerConfigDuplicate",
                            e.target.checked
                          )
                        }
                      />
                    }
                    label="Allow Merger Duplication"
                  />
                </FormGroup>
              </Box>
            ) : (
              <></>
            )}
          </Stack>

          <Stack direction="row" justifyContent="start" spacing={1}>
            <Box sx={{ flex: 1 }} className={["flexLeft"].join(" ")}>
              <FormControl sx={{ flex: 1 }}>
                <InputLabel sx={{ marginTop: "10px" }}>{"Bundling Option"}</InputLabel>
                <Select
                  sx={{ marginTop: "10px" }}
                  label="Bundling Option"
                  name="BundlingOption"
                  value={documentBundleState?.BundlingOption}
                  onChange={handleSelectChange}
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value={OneFolderPerDocument}>
                    One Folder Per Document
                  </MenuItem>
                  <MenuItem value={OneFolderPerPackage}>
                    One Folder Per Package
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ flex: 1, padding: "14px" }} className={["flexRight"].join(" ")}></Box>
          </Stack>

          <Card sx={{ mt: 1 }}>
            <CardContent>
                        <Typography variant="subtitle1" component="h2">{title}</Typography>
              {documentBundleState?.BundlingOption === OneFolderPerDocument ? (
                            <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }} className={["flexRight"].join(" ")}>
                  <DTDataGrid
                    columns={packageConfigColumns(
                      handleItemSelectChangeDocumentHandlingPackageConfig,
                      handleItemCheckBoxChangeDocumentHandlingPackageConfig,
                      handleItemInputChangeDocumentHandlingPackageConfig,
                                        [...documentMapsPrefix, ...documentMappings?.Configuration?.DocumentMaps.map(x => x.DocumentIndex) ?? []]
                    )}
                    data={
                      documentBundleState?.DocumentHandlingPackageConfigs ?? []
                    }
                    tableName="packageConfigs"
                                    containerSx={{ maxHeight: "30vh", minHeight: "30vh", width: "100%" }}
                    onRowClick={handleRowClick}
                  />
                </Box>
              ) : (
                            <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }} className={["flexRight"].join(" ")}>
                  <DTDataGrid
                    columns={mergePackageConfigColumns(
                      handleItemSelectChangeMergerPackageConfig,
                      handleItemCheckBoxChangeMergerPackageConfig,
                      handleItemInputChangeMergerPackageConfig,
                                        [...documentMapsPrefix, ...documentMappings?.Configuration?.DocumentMaps.map(x => x.DocumentIndex) ?? []]
                    )}
                    data={documentBundleState?.MergerPackageConfigs ?? []}
                    tableName="mergepackageConfigs"
                    containerSx={{ maxHeight: "30vh", minHeight: "30vh" }}
                    onRowClick={handleRowClick}
                  />
                </Box>
              )}

                        <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "flex-start", gap: 1 }}>
                  <Button variant="outlined" size="small" onClick={addRow}>
                    Add
                  </Button>
                  <Button variant="outlined" size="small" onClick={removeRow}>
                    Remove
                  </Button>
                </Box>
                <Box>
                                {
                                    documentBundleState?.BundlingOption === OneFolderPerDocument ? (
                                        <Typography variant="h6" sx={{ color: (theme) => theme.palette.warning.main, fontSize: '0.875rem' }}>
                      Rows where 1st column is empty are deleted
                    </Typography>

                  ) : (
                                        <Typography variant="h6" sx={{ color: (theme) => theme.palette.warning.main, fontSize: '0.875rem' }} >
                                            Rows where 1st column is empty are deleted. Encompass Folder is required.
                    </Typography>
                                    )
                                }
                </Box>
              </CardActions>
            </CardContent>
          </Card>
        </Box>
        <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={alertOpen}
          autoHideDuration={4000}
          onClose={handleSnackBarClose}
        >
          <MuiAlert
            onClose={handleSnackBarClose}
            severity={alertSeverity}
                    sx={{ width: '100%', fontSize: '1.2rem', padding: '12px 16px' }}>
            {alertMessage}
          </MuiAlert>
        </Snackbar>
      </GenericDialog>
    );
};

export default ConfigDocumentHandlingDialog;