import { Box, Button, Card, CardContent, CardHeader, Checkbox, Container, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Snackbar, Stack, Tooltip, Typography } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { useSelector } from "react-redux";
import { RootState, store } from "../state/store";
import { useEffect, useState } from "react";
import { IDeliveryType, IPackage } from "../models/configuration/plugin/IConfiguration";
import { useTypedSelector } from "../hooks/useTypedSelector";
import AttachmentsCard from "../components/AttachmentsCard";
import OrderTabs from "../components/order/OrderTabs";
import SelectToPrintCard from "../components/order/SelectToPrintCard";
import OrderButton from "../components/order/OrderButton";
import { GetControlOptions } from "../utils/controlOptionsHelper";
import { IControlsOptionsHelper } from "../models/IControlsOptionsHelper";
import { BUTTON_LABELS, PANEL_IDS, REQUEST_TYPES, REQUEST_TYPE_LABEL } from "../utils/constants";
import GenericDialog from "../components/shared/GenericDialog";
import EvaluationResults from "../components/order/EvaluationResults";
import { AlertSeverity } from "../constants/AlertTypes";
import { IDocListItem } from "../models/IDocListResponse";
import { IRequestId } from "../models/IRequestIds";
import Footer from "../layout/Footer";
import RefreshButton from "../components/order/RefreshButton";

const OrderPage = () => {
    //Page Access/Visibility states
    const [deliveryMethods, setDeliveryMethods] = useState<Array<IDeliveryType>>([])
    const appState = useSelector((state: RootState) => state.appSlice)
    const controlsState = useSelector((state: RootState) => state.configPlugInData.data?.Configuration.OtherControls)
    const packages = useTypedSelector((state) => state.configPlugInData.data?.Configuration.Packages);
    const configMisc = useTypedSelector((state) => state.configPlugInData.data?.Configuration?.DefaultValues)
    const [packageTypes, setPackageTypes] = useState<Array<IPackage>>([]);
    const [attachmentsVisible, setAttachmentsVisible] = useState(false);
    const [attachmentsEnable, setAttachmentsEnable] = useState(false);
    const [selectedVisible, setSelectedVisible] = useState(false);
    const [selectedEnable, setSelectedEnable] = useState(false);
    const [requestTypeVisible, setRequestTypeVisible] = useState(false);
    const [requestTypeEnable, setRequestTypeEnable] = useState(false);
    const [requestTypeTitle, setRequestTypeTitle] = useState(REQUEST_TYPE_LABEL);
    const [attachmentsTitle, setAttachmentsTitle] = useState("Attachments");
    const [selectToPrintTitle, setSelectToPrintTitle] = useState("Selected");
    const [orderCount, setOrderCount] = useState(0);
    const deliveryMethodState = useSelector((state: RootState) => state.configPlugInData.data?.Configuration.DeliveryTypes);
    const [requestType, setRequestType] = useState(configMisc?.DefaultRequestType ?? REQUEST_TYPES.LIGHTS_OUT);
    const [packageId, setPackageId] = useState('');
    const [selectedDeliveryMethods, setSelectedDeliveryMethods] = useState<Array<IDeliveryType>>([]);
    const [autoEvaluationResultsOpen, setAutoEvaluationResultsOpen] = useState(false);
    const [altPackageId, setAltPackageId] = useState('');  
    const [logsTabVisible, setLogsTabVisible] = useState(false);
    const [logsTabEnable, setLogsTabEnable] = useState(false);
    const [requestResponseTabVisible, setRequestResponseTabVisible] = useState(false);
    const [requestResponseTabEnable, setRequestResponseTabEnable] = useState(false);
    const [logsTabTitle, setLogsTabTitle] = useState("LOGS");
    const [requestResponseTabTitle, setRequestResponseTabTitle] = useState("REQUEST/RESPONSE");  
    const [parentPackageId, setParentPackageId] = useState('');
    const requests = useTypedSelector<Array<IRequestId>>((state)=> appState.requestIds);
 

    //select to print 
    const [documentList, setDocumentList] = useState<Array<IDocListItem>>([]);
    const [selectedDocumentList, setSelectedDocumentList] = useState<Array<IDocListItem>>([])

    // Alert
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('MESSAGE');
    const [alertSeverity, setAlertSeverity] = useState<AlertSeverity>('info');
    const handleSnackBarClose = (_event: any) => { setAlertOpen(false); }
    const openAlert = (message: string, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
        setAlertSeverity(severity);
        setAlertMessage(message);
        setAlertOpen(true);
    }

    const handlePackageChange = (dm: string[]) => {
        const lowerCaseDeliveryMethods = dm.map(d => d?.toLocaleLowerCase());
        const packageTypeDetails = deliveryMethodState?.filter(d => lowerCaseDeliveryMethods.includes(d.Id.toLowerCase())) ?? [];
        setDeliveryMethods(packageTypeDetails);
        setSelectedDeliveryMethods([]);
        setSelectedDocumentList([]);
        setDocumentList([]);
    }

    const setControlOptions = (opts: IControlsOptionsHelper, setVisible: any, setEnable: any, setTitle: any) => {
        setVisible(opts.IsVisible);
        setEnable(opts.IsEnabled);
        setTitle(opts.Title);
    }

    const handleDeliveryMethodClick = (e: any) => {
        const checked = e.target.checked;
        const val = e.target.value;
        const deliveryObj = deliveryMethods.find(d => d.Id === val);
        if (checked && deliveryObj) {
            if (!selectedDeliveryMethods.some(d => d.Id === val)) {
                setSelectedDeliveryMethods(selectedDeliveryMethods => [...selectedDeliveryMethods, deliveryObj]);
            }
        } else {
            if (selectedDeliveryMethods.some(d => d.Id === val)) {
                setSelectedDeliveryMethods(selectedDeliveryMethods => selectedDeliveryMethods.filter(dm => dm.Id !== val));
            }
        }
    }

    useEffect(() => {
        let pt = packages?.filter((d) => d.Group === appState.page || d.ParentPackageId === appState.page);
        setPackageTypes(pt?.sort((a, b) => (a.Order ?? 999) - (b.Order ?? 999)) ?? []);
        setPackageId('');
        setDeliveryMethods([])
    }, [appState.page, packages])

    useEffect(() => {
        const otherControlOptions = controlsState;
        const attachmentControlOpts = otherControlOptions?.find(c => c.Id === PANEL_IDS.ATTACHMENTS);
        const selectDocOpts = otherControlOptions?.find(c => c.Id === PANEL_IDS.SELECT_TO_PRINT);
        const requestTypeControlOpts = otherControlOptions?.find(c => c.Id === PANEL_IDS.REQUEST_TYPE);
        const logsTabControlOpts = otherControlOptions?.find(c => c.Id === PANEL_IDS.LOGS_TAB);
        const requestResponseTabControlOpts = otherControlOptions?.find(c => c.Id === PANEL_IDS.REQUEST_RESPONSE_TAB);
        const currentAppState = store.getState().appSlice;
        const attachOptions = GetControlOptions(attachmentControlOpts, "Add Attachments", currentAppState);
        const selectDocOptions = GetControlOptions(selectDocOpts, "Select to Print", currentAppState); 
        const requestTypeOptions = GetControlOptions(requestTypeControlOpts,REQUEST_TYPE_LABEL, currentAppState);
        const logsTabControlOptions = GetControlOptions(logsTabControlOpts, "Logs", currentAppState);
        const requestResponseTabControlOptions = GetControlOptions(requestResponseTabControlOpts, "Request/Response", currentAppState);
        setControlOptions(attachOptions, setAttachmentsVisible, setAttachmentsEnable, setAttachmentsTitle);
        setControlOptions(selectDocOptions, setSelectedVisible, setSelectedEnable, setSelectToPrintTitle);
        setControlOptions(requestTypeOptions, setRequestTypeVisible, setRequestTypeEnable, setRequestTypeTitle);
        setControlOptions(logsTabControlOptions, setLogsTabVisible, setLogsTabEnable, setLogsTabTitle);
        setControlOptions(requestResponseTabControlOptions, setRequestResponseTabVisible, setRequestResponseTabEnable, setRequestResponseTabTitle); 
    }, [controlsState])

    const submitHandler = () => {
        setOrderCount(prevCount => prevCount + 1)
    }

    const handleRequestTypeChange = (e: any) => {
        setRequestType(e.target.value);
        setSelectedDeliveryMethods([]);
    }
    
    return (<div>
        <Box
            component="main"
            alignItems="top"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                overflow: "auto",
                minHeight: '100vh',
            }}
        >
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={alertOpen}
                autoHideDuration={4000}
                onClose={handleSnackBarClose}
            >
                <MuiAlert
                    onClose={handleSnackBarClose}
                    severity={alertSeverity}
                    sx={{ width: '100%', fontSize: '1.2rem', padding: '12px 16px' }}>
                    {alertMessage}
                </MuiAlert>
            </Snackbar>
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }} >
                <Grid container spacing={3} justifyContent="space-between" alignItems="stretch">
                    <Grid item xs={6}  >
                        <Typography variant="h4">{appState.page}</Typography>
                    </Grid>
                    <Grid item xs={6}  >
                        <Stack direction="row" justifyContent="end" spacing={1}>                            
                            <RefreshButton/>
                            <OrderButton
                                altPackageId={altPackageId}
                                parentTypeId={parentPackageId}
                                requestType={requestType}
                                packageId={packageId}
                                deliveryMethods={selectedDeliveryMethods}
                                disabled={(configMisc?.RequireRefreshBeforeSubsequentSend && orderCount > 0) || packageId === ''}
                                orderCountHandler={submitHandler}
                                alertHandler={openAlert}
                                selectedDocuments={selectedDocumentList}
                                token={appState.accessToken ?? ""}
                                alwaysPreviewDocuments={configMisc?.PreviewAttachmentsAlways ?? false}
                                buttonText={ requestType === REQUEST_TYPES.LIGHTS_OUT && selectedDeliveryMethods.length < 1 ? BUTTON_LABELS.PREVIEW: BUTTON_LABELS.SEND }
                            />
                            {configMisc?.RequestTypeAvailability === REQUEST_TYPES.BOTH && requestTypeVisible?
                                <FormControl sx={{ m: 1, minWidth: 220 }} size="small" disabled={!requestTypeEnable}>
                                    <InputLabel>{requestTypeTitle}</InputLabel>
                                    <Select label={requestTypeTitle} defaultValue={configMisc?.DefaultRequestType} onChange={handleRequestTypeChange}>
                                        {
                                            [REQUEST_TYPES.LIGHTS_OUT, REQUEST_TYPES.LIGHTS_ON]?.map((rt, i) => {
                                                return <MenuItem value={rt} key={`requestType-${i}`}>{rt}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl> : null}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Card
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 300,
                            }}
                        >
                            <CardContent>
                                <FormControl>
                                    <RadioGroup
                                        value={packageId}
                                        name={`${appState.page?.replace(/\s/g, '')}-radio}`}>
                                        {packageTypes.filter(p => p.Enabled).map((p, i) =>
                                            <>
                                            <FormControlLabel
                                                sx={{
                                                    color: !p.ParentPackageId ? 'black' : configMisc?.AlternatePackageColor
                                                }}
                                                value={p.Id}
                                                control={ <Radio />}
                                                label={
                                                    <Tooltip title={p.ToolTipText ?? ''} 
                                                        PopperProps={{ modifiers: [{ name: 'offset', options: { offset: [50, 0] } }] }}>
                                                        <span>{p.Caption ? p.Caption : p.Id}</span>
                                                    </Tooltip>
                                                }
                                                key={`packageType-${i}`}
                                                onChange={() => {
                                                    handlePackageChange(p.ValidDeliveryTypes ?? []);
                                                    setPackageId(p.Id ?? "")
                                                    setAltPackageId(p.AlternatePackageId ?? "");
                                                    setParentPackageId(p.ParentPackageId ?? "");
                                                }}
                                            />
                                            </>
                                        )}
                                    </RadioGroup>
                                </FormControl>
                            </CardContent>
                        </Card>
                    </Grid>
                    {/* Recent Deposits */}
                    <Grid item xs={12} md={6} lg={6}>
                            <Card
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 300,
                                }}
                            >
                                <CardHeader sx={{color: requestType === REQUEST_TYPES.LIGHTS_ON ? 'grey': 'black'}} title="Delivery Method"></CardHeader>
                                <CardContent sx={{ overflow: 'auto' }}>
                                { requestType === REQUEST_TYPES.LIGHTS_OUT &&
                                    <FormGroup>
                                        {deliveryMethods.map((dm, i) =>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        value={dm.Id}
                                                        checked={selectedDeliveryMethods.some(selected => selected.Id === dm.Id)}
                                                        onChange={(e) => handleDeliveryMethodClick(e)}
                                                    />
                                                }
                                                label={dm.Caption ?? dm.Id}
                                                key={`dmethod-${i}`}
                                            />
                                        )}
                                    </FormGroup>
                                }
                                </CardContent>
                            </Card>
                    </Grid>
                    {/* Recent Orders */}
                    <Grid item xs={6}>
                        <AttachmentsCard visible={attachmentsVisible} enabled={attachmentsEnable} title={attachmentsTitle} />
                    </Grid>
                    <Grid item xs={6}>
                        {configMisc?.RequestTypeAvailability !== REQUEST_TYPES.LIGHTS_ON &&
                            <SelectToPrintCard
                                packageId={packageId}
                                altPackageId={altPackageId}
                                parentPackageId={parentPackageId}
                                visible={selectedVisible}
                                enabled={selectedEnable}
                                title={selectToPrintTitle}
                                docList={documentList}
                                selectedDocList={selectedDocumentList}
                                setListMethod={setDocumentList}
                                setSelectedListMethod={setSelectedDocumentList} 
                            />
                        }
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth="xl" sx={{ mt: 4, mb: 1 }}>
                <Grid item xs={12} lg={12} xl={12}>
                    <OrderTabs
                        logsTabVisible={logsTabVisible}
                        logsTabEnabled={logsTabEnable}
                        logsTabTitle={logsTabTitle}
                        requestResponseTabVisible={requestResponseTabVisible}
                        requestResponseTabEnabled={requestResponseTabEnable}
                        requestResponseTabTitle={requestResponseTabTitle}
                        requests={requests} authToken={appState.accessToken??""}
                    />
                </Grid>
            </Container>
            <Container sx={{ mt: 0, mb: 1 }}>
                <Grid item xs={12} justifyContent="end">
                    <Stack direction="row" justifyContent="end" spacing={1}>
                        <Button variant="contained" size="small" color="info" onClick={() => setAutoEvaluationResultsOpen(true)} >Why can't I disclose?</Button>
                    </Stack>
                </Grid>
            </Container>
        </Box>
        <GenericDialog
            open={autoEvaluationResultsOpen}
            onClose={() => setAutoEvaluationResultsOpen(false)}
            title="Control Evaluation Results"
            containerSx={{overflow:"hidden"}}
        >
            <EvaluationResults packageList={packages} />
        </GenericDialog>
        <Footer/>
    </div>
    )
};

export default OrderPage;
