import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';

interface InputDialogProps {
  open: boolean;
  title?: string,
  submitButtonTitle?: string,
  showValue?: boolean;
  onClose: () => void;
  onSubmit: (value: string) => void;
}

const InputDialog: React.FC<InputDialogProps> = ({ 
    open, 
    title = 'Value', 
    submitButtonTitle = 'Submit', 
    showValue = true, 
    onClose, 
    onSubmit }) => {

  const [inputValue, setInputValue] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [submitValidationError, setSubmitValidationError] = useState<string|null>(null);

  const handleSubmit = () => {

    if (showValue) {
        if (inputValue.trim()) {
          var res:any = onSubmit(inputValue.trim());
          if(res)
            {
              setSubmitValidationError(res);
              setError(true); 
            }
            else{
              {
                setError(false);
                setInputValue("");
                setSubmitValidationError("");
                onClose();
              }
            }
            } else {
            setSubmitValidationError("");
            setError(true); 
            }
    } else {
        onSubmit("");
    }
    
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (e.target.value.trim()) {
      setError(false); 
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>

      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {showValue && (
            <TextField
                autoFocus
                margin="dense"
                label="Value"
                type="text"
                fullWidth
                variant="standard"
                value={inputValue}
                onChange={handleChange}
                error={error} // Highlight input if error is true
                helperText={error ? submitValidationError?submitValidationError:'Value is required' : ''} // Show error message
            />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>{submitButtonTitle || "Submit"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default InputDialog;