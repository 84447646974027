import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { ExpressionValidationResult } from '../../../utils/expressionHelper';
import { IExpression } from '../../../models/configuration/plugin/IConfiguration';

interface ExpressionEditorProps {
	expression: IExpression;
	onSave?: (updatedExpression: IExpression, editorMode: string) => void;
	onValidate: (expression: IExpression) => ExpressionValidationResult | null;
	editorMode: string | null
}

interface IExpressionValidation {
	stringIsValid: boolean | undefined
	nameIsValid: boolean,
	errors: string[] | undefined,
	isValid: () => boolean
}

const initialExpressionValidationState: IExpressionValidation = {
	stringIsValid: false,
	nameIsValid: false,
	errors: [],
	isValid() { return !!this.stringIsValid && this.nameIsValid; }
}

const initialExpressionState: IExpression = {
	ExpressionName: "",
	ExpressionString: "",
	ExpressionMessage: "",
	Enabled: false
}

enum Mode {
	Init = "initial",
	Add = 'add',
	Edit = 'edit'
}

const ExpressionEditor: React.FC<ExpressionEditorProps> = ({ expression, onSave, onValidate, editorMode }) => {
	const [expressionState, setExpressionState] = useState<IExpression>(initialExpressionState);
	const [expressionValidation, setExpressionValidation] = useState<IExpressionValidation>(initialExpressionValidationState);
	const [mode, setMode] = useState<Mode>(Mode.Init)

	useEffect(() => {
		if (expression)
			setExpressionState(expression);

		if (editorMode === 'add')
			setMode(Mode.Add);
		else if (editorMode === 'edit')
			setMode(Mode.Edit);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // Initial load

	useEffect(() => {
		if (mode === Mode.Add) {
			setExpressionState(initialExpressionState);
		}
	}, [mode])

	useEffect(() => {

		if (expression)
			setExpressionState(expression);

		if (editorMode === 'add')
			setMode(Mode.Add);
		else if (editorMode === 'edit')
			setMode(Mode.Edit);

	}, [expression, editorMode])

	const handleClearExpressionString = () => {
		setExpressionState(prevState => ({
			...prevState,
			ExpressionString: '',
		}));
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = e.target;
		setExpressionState(prevState => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleSave = () => {
		if (onSave) {
			onSave(expressionState, mode);
		}
	};

	const handleValidate = () => {
		const validString = onValidate(expressionState); // Call Parent Validator 
		const validName = expressionState.ExpressionName.length > 0;

		if (!validName)
			validString?.errors.push("Name is required");

		setExpressionValidation(
			{
				...expressionValidation,
				stringIsValid: validString?.isValid,
				errors: validString?.errors,
				nameIsValid: validName
			})
	};

	const showSaveButton = (): boolean => {
		if (expressionState.ExpressionString.length === 0) {
			// Handle the clear case 
			return true;
		} else {
			// Handle the validation case
			return expressionValidation.nameIsValid && (expressionValidation.stringIsValid || false);
		} 
	}

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, height: '100%' }}>
			<TextField
				sx={{ marginTop: "10px" }}
				label="Expression Name"
				name="ExpressionName"
				value={expressionState.ExpressionName}
				onChange={handleInputChange}
				fullWidth
				required
				helperText="Expression Name must be unique"
				variant="outlined"
			/>

			<TextField
				label="Expression Description (Friendly)"
				name="ExpressionMessage"
				value={expressionState.ExpressionMessage}
				onChange={handleInputChange}
				fullWidth
				multiline
				rows={5}
				variant="outlined"
			/>

			<TextField
				label="Encompass Expression (VB Syntax Recommended)"
				name="ExpressionString"
				value={expressionState.ExpressionString}
				onChange={handleInputChange}
				fullWidth
				multiline
				rows={8}
				variant="outlined"
			/>

			<Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 2 }}>
				<Button variant="contained" color="secondary" onClick={handleClearExpressionString}>
					Clear Expression
				</Button>
				<Typography paddingLeft={2}>Useful Process Fields [CX.DOCUTECH.SELECTED.PACKAGE] [CX.DOCUTECH.DELIVERYTYPES]</Typography>
			</Box>

			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1' }}>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Button variant="contained" color="primary" onClick={handleValidate}>
						Validate
					</Button>
					<Typography paddingLeft={2}>Expression must be 'Validated' before a Save is allowed</Typography>
				</Box>
				{ showSaveButton() && (
					<Button variant="contained" color="primary" onClick={handleSave}>
						Save
					</Button>
				)}
			</Box>

			{!expressionValidation?.isValid() && expressionValidation?.errors && expressionValidation?.errors.length > 0 && (
				<Box sx={{ marginTop: 1 }}>
					<Typography sx={{ fontWeight: 'bold' }}>Errors List:</Typography>
					<Box sx={{ paddingLeft: 2 }}>
						{expressionValidation?.errors?.map((error, index) => (
							<Typography key={index} sx={{ color: 'red' }}>
								{`Error ${index + 1}: ${error}`}
							</Typography>
						))}
					</Box>
				</Box>
			)}
			{expressionValidation?.isValid() && (
				<Typography paddingLeft={2} sx={{ color: 'green' }}>Valid Expression</Typography>
			)}

		</Box>
	);
};

export default ExpressionEditor;