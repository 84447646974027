import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Grid, Tab, Tabs, Typography } from '@mui/material';
import GenericList from '../../shared/GenericList';
import GenericListSelected from '../../shared/GenericListSelected';
import InputDialog from '../../shared/InputDialog'
import CustomTabPanel from '../../shared/CustomTabPanel';
import { IPackage, IExpression } from '../../../models/configuration/plugin/IConfiguration';
import ExpressionsControl from '../../shared/config/ExpressionsControl';

const ENVROLES = process.env.REACT_APP_DT_DEFAULT_ROLES || '';

interface packagesTabProps {
    tabIndex: number,
    selectedPackage: IPackage | null;
    updateParentPackage: (updatedPackage: IPackage) => void;
    sendUserUpdate: (users: string[]) => void;
    children?: React.ReactNode
}

const PackagesTab: React.FC<packagesTabProps> = ({ selectedPackage, updateParentPackage, sendUserUpdate }) => {

    const defaultRoles = ENVROLES.split(',');
    const [selectedPackageState, setSelectedPackageState] = useState<IPackage | null>(null);
    const [roles] = useState<string[]>(defaultRoles);
    const [selectedRoles, setSelectedRoles] = useState<string[] | undefined>([]);
    const [users, setUsers] = useState<string[] | undefined>([]);
    const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
    const [removeUserDialogOpen, setRemoveUserDialogOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<string | null>(null);
    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    // Selected Package Changed
    useEffect(() => {
        if (selectedPackage)
            setSelectedPackageState(selectedPackage);
    }, [selectedPackage]);

    useEffect(() => {

        if (selectedPackageState != null) {
            setSelectedRoles(selectedPackageState?.Roles);
            setUsers(selectedPackageState?.Users);

        } else {
            setSelectedRoles([]);
            setUsers([]);
        }

    }, [selectedPackageState]);

    /* Roles */
    const onRoleSelected = (item: string) => {
        let updatedRoles: string[];
        if (selectedRoles?.includes(item))
            updatedRoles = selectedRoles.filter(role => role !== item);
        else
            updatedRoles = [...(selectedRoles ?? []), item];

        setSelectedRoles(updatedRoles);

        if (selectedPackageState) {
            const updatedControl = { ...selectedPackageState, Roles: updatedRoles };
            updateParentPackage(updatedControl);
        }
    }

    /* Users */

    // Add User
    const handleAddUserDialogOpen = () => { setAddUserDialogOpen(true); }
    const handleAddUserDialogClosed = () => { setAddUserDialogOpen(false); }
    const handleAddUserInputtedValue = (value: string) => {
        if (users?.includes(value)) {
            return "User already exists";
        }
        else {
            let userList = users || [];
            const updatedUserList = [...userList, value];
            setUsers(updatedUserList);
            sendUserUpdate(updatedUserList);
        }
    }

    const onUserSelected = (user: string | null) => {
        setSelectedUser(user);
    }

    // Remove User
    const handleRemoveUserDialogOpen = () => { setRemoveUserDialogOpen(true); }
    const handleRemoveUserDialogClosed = () => { setRemoveUserDialogOpen(false); }
    const handleRemoveUserValue = (user: string | null) => {
        if (user && users) {
            const updatedUsers = users.filter(currentUser => {
                return currentUser !== user
            });
            setUsers([...updatedUsers]);
            sendUserUpdate([...updatedUsers]);
            setRemoveUserDialogOpen(false);
        }
    };

    /* Expressions */
    const updateExpressions = (updatedExpressions: IExpression[]) => {
        if (selectedPackageState && selectedPackageState.Expressions) {
            const newPackage = { ...selectedPackageState, Expressions: updatedExpressions };
            setSelectedPackageState(newPackage);
            updateParentPackage(newPackage);
        }
    }

    function TabProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (

        <div>
            <Card sx={{ display: 'flex', flexDirection: 'column', margin: 2 }}>
                <CardContent>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="Security" {...TabProps(0)} />
                        <Tab label="Expressions" {...TabProps(1)} />
                    </Tabs>
                    <CustomTabPanel value={tabValue} index={0}>
                        <Box sx={{ p: 3 }}>
                            {/* // TODO: Refactor to use Flexbox */}
                            <Grid container spacing={2} alignItems="center" margin={1}>
                                <Grid item xs={6}>
                                    <Card sx={{ display: 'flex', flexDirection: 'column', margin: 2, height: '400px' }}>
                                        <CardContent>
                                            <Typography>Choose Roles with Access</Typography>
                                            <Box sx={{ flexGrow: 1, overflowY: 'auto', maxHeight: '375px' }}>
                                                <GenericListSelected
                                                    items={roles}
                                                    selectedItems={selectedRoles}
                                                    onItemClick={onRoleSelected}
                                                />
                                            </Box>
                                        </CardContent>
                                    </Card>
                                    <Typography sx={{ color: (theme) => theme.palette.warning.main, marginLeft: "15px"  }}> Multi-Select Enabled</Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <Card sx={{ display: 'flex', flexDirection: 'column', margin: 2, height: '400px' }}>
                                        <CardContent>
                                            {selectedPackageState && (
                                                <Button variant="contained" size="small" sx={{ margin: 1 }}
                                                    onClick={handleAddUserDialogOpen}>Add</Button>
                                            )}
                                            {
                                                addUserDialogOpen &&
                                            <InputDialog
                                                title="Enter a username"
                                                submitButtonTitle="Submit"
                                                open={addUserDialogOpen}
                                                onClose={handleAddUserDialogClosed}
                                                onSubmit={handleAddUserInputtedValue}
                                                showValue={true}
                                            />
                                            }
                                            {selectedUser !== null && selectedPackageState && Array.isArray(selectedPackageState.Users) && selectedPackageState.Users.length > 0 && (
                                                <Button variant="contained" size="small" color="error"
                                                    onClick={handleRemoveUserDialogOpen}>Remove</Button>
                                            )}
                                            {
                                                removeUserDialogOpen &&
                                            <InputDialog
                                                title="Remove selected user"
                                                submitButtonTitle="Remove"
                                                open={removeUserDialogOpen}
                                                onClose={handleRemoveUserDialogClosed}
                                                onSubmit={() => handleRemoveUserValue(selectedUser)}
                                                showValue={false}
                                            />
                                            }

                                            <Typography sx={{ margin: 1 }}>Users with Access</Typography>
                                            <Box sx={{ flexGrow: 1, overflowY: 'auto', maxHeight: '375px' }}>
                                                <GenericList
                                                    items={users}
                                                    onSelected={onUserSelected}
                                                />
                                            </Box>

                                        </CardContent>
                                    </Card>
                                    <Typography sx={{ color: (theme) => theme.palette.warning.main, marginLeft: "15px"  }}> One per line</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={1}>
                        <ExpressionsControl
                            expressions={selectedPackageState?.Expressions}
                            updateParentExpressions={updateExpressions}
                        />
                    </CustomTabPanel>
                </CardContent>
            </Card>
        </div>
    )
}

export default PackagesTab;