import React, { useState } from "react";
import { TableCell, TextField, Box } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import "../../../assets/style.css";

interface DTDataGridTextEditProps {
  text: string;
  cellKey: string;
  alignmentClass: string | undefined;
  dataChangeFunction?: (e: any, item: any) => void;
  rowIndex: number;
  itemName: string;
  editOnClick: boolean | undefined;
  foreColor: string | undefined;
  backColor: string | undefined;
}

const DTDataGridTextEdit: React.FC<DTDataGridTextEditProps> = (
  props: DTDataGridTextEditProps
) => {
  const [inEdit, setInEdit] = useState(false);
  const cellText = Array.isArray(props.text) && props.itemName == "MergeList" ? props.text.join() : props.text

  return (
    <>
      {
        <TableCell
          key={props.cellKey}
          className={[props.alignmentClass, "height100"].join(" ")}
          sx={{
            background: props.backColor,
          }}
          tabIndex={0} 
        >
          {inEdit && props.editOnClick ? (
            <>
              <div key={props.cellKey} className={["height100"].join(" ")}>
                <TextField
                  hiddenLabel
                  name={props.itemName}
                  key={props.cellKey}
                  margin="none"
                  value={props.text ?? ""}
                  fullWidth
                  variant="outlined"
                  onBlur={() => setInEdit(false)}
                  onChange={(e) =>
                    props.dataChangeFunction
                      ? props.dataChangeFunction(e, props.rowIndex)
                      : null
                  }
                  size="small"
                />
              </div>
            </>
          ) : (
              <div
                key={props.cellKey}
                className={[props.alignmentClass, "height100"].join(" ")}
                onClick={() => (props.editOnClick ? setInEdit(!inEdit) : null)}
              >
                {cellText?.length > 20 && props.itemName == "MergeList" ?
                  (<Tooltip title={cellText}>
                    <Box
                      // className="dtcelltrunc" // keep for reference 
                      sx={{
                        color: props.foreColor
                      }}
                    >
                      {cellText?.length > 20 && props.itemName == "MergeList" ? `${cellText.substring(0, 17)}...` : cellText}
                    </Box>
                  </Tooltip>) : (
                    <Box
                      sx={{
                        color: props.foreColor
                      }}
                    >
                      {cellText}</Box>)
                }
              </div>
          )}
        </TableCell>
      }
    </>
  );
};

export default DTDataGridTextEdit;