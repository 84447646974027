import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { FieldMapping } from '../../models/configuration/xpathFieldMap/IXpathFieldMap';

interface XPathGridItemAddDialogProps {
    open: boolean;
    closeFxtn: () => void;
    title?: string | undefined;
    updateFxtn: (item: FieldMapping) => void;
}

const XPathGridItemAddDialog: React.FC<XPathGridItemAddDialogProps> = ({ open, closeFxtn, title, updateFxtn }) => {

    const [itemState, setItemState] = useState<FieldMapping | null>(null);

    const closeDialog = () => { closeFxtn(); }
    
    useEffect(() => {
        if (open) {
            setItemState(null);
        }
    }, [open]);

    const updateItem = () => {
        if (!itemState)
            return;
        updateFxtn(itemState!);
    }

    const clearFields = () => {
        if (itemState) {
            setItemState({...itemState, 
                Name: "",
                EncompassFieldId: "",
                Xpath: ""   
            });
        }
    }

    return (
        <Dialog open={open} onClose={closeDialog}
            sx={{ width: '100%', height: '100%' }}
        >
            <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                <span>{title ?? "Title"}</span>
                <IconButton
                    aria-label="close"
                    onClick={closeDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            gap: 2,
                            height: "100%",
                        }}
                    >
                        <Box sx={{ fontWeight: "bold" }}>Description:</Box>
                        <TextField
                            onChange={(e) => setItemState({ ...itemState!, Name: e.target.value })}
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={itemState?.Name}
                            sx={{ flex: 1, width: "350px" }}
                        />
                        <Box sx={{ fontWeight: "bold" }}>Output Encompass Path:</Box>
                        <TextField
                            onChange={(e) => setItemState({ ...itemState!, EncompassFieldId: e.target.value })}
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={itemState?.EncompassFieldId}
                            sx={{ flex: 1, width: "350px" }}
                        />
                        <Box sx={{ fontWeight: "bold" }}>Input XPath:</Box>
                        <TextField
                            aria-label="minimum height"
                            onChange={(e) => setItemState({ ...itemState!, Xpath: e.target.value })}
                            multiline
                            minRows={3}
                            value={itemState?.Xpath}
                            fullWidth
                            variant="outlined"
                        />

                    </Box>

                    <Stack direction="row" spacing={2} justifyContent="flex-end">
                        <Button 
                            variant="contained" 
                            onClick={updateItem}
                            disabled={!itemState?.Name || !itemState?.EncompassFieldId || !itemState?.Xpath}
                            >Save</Button>
                        <Button variant="contained" onClick={clearFields}>Clear</Button>
                        <Button variant="contained" onClick={closeDialog}>Close</Button>
                    </Stack>

                </Stack>
            </DialogContent>
        </Dialog>
    );
}

export default XPathGridItemAddDialog;