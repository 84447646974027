import React from 'react';
import { Card, CardContent, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { IDeliveryType } from '../../../models/configuration/plugin/IConfiguration';

interface DeliveryTypesListProps {
    deliveryTypes: IDeliveryType[] | null,
    selectedDeliveryTypeIdx: string | null,
    selectDeliveryType: (id: string) => void,
    deliveryTypeTree: any[] | null
}

const DeliveryTypesList: React.FC<DeliveryTypesListProps> =
    ({selectedDeliveryTypeIdx, selectDeliveryType, deliveryTypeTree}) => {
        return (
            // I want the card to overflow and scroll if the content is too large
            <Card sx={{ display: 'flex', flexDirection: 'column', margin: 4 , maxHeight: "31rem", overflowY: 'auto' }}>
                <CardContent>
                    {deliveryTypeTree && 
                        (deliveryTypeTree.length === 0 ? <div>No delivery types found</div>
                        :
                        <List>
                            {deliveryTypeTree?.map((dt) => (
                                <ListItemText primary = {
                                    <ListItemButton
                                        key={dt.Id}
                                        selected={selectedDeliveryTypeIdx === dt.Id}
                                        onClick={() => selectDeliveryType(dt.Id)}
                                        sx={{color: dt.Enabled ? 'blue' : 'grey'}}>
                                        {dt.Id}
                                    </ListItemButton>
                                    }
                                        secondary={dt.children?.length > 0 &&
                                            dt.children?.map((dtc:any) =>(
                                                <ListItem sx={{ py: 0}}>
                                                    <ListItemText primary={
                                                        <ListItemButton
                                                            key={dtc.Id} 
                                                            sx={{ color: dtc.Enabled ? 'blue' : 'grey'}}
                                                            selected={selectedDeliveryTypeIdx === dtc.Id}
                                                            onClick={() => selectDeliveryType(dtc.Id)}>
                                                            {dtc.Id}
                                                        </ListItemButton>} />
                                                </ListItem>
                                        ))} />
                            ))}
                        </List>
                    )}
                </CardContent>
            </Card>
        );
    }

export default DeliveryTypesList;