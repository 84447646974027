import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import MuiAlert from '@mui/material/Alert';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Snackbar,
  SnackbarCloseReason,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../../assets/style.css";
import ConfigLoad from "../../components/shared/config/ConfigLoad";
import ConfigBottomBar from "../../components/shared/config/ConfigBottomBar";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import EncompassService from "../../services/encompassService";
import { IDefaultValues } from "../../models/configuration/plugin/IConfiguration";
import {
  REQUEST_TYPES,
  SAVE_LOAN_OPTIONS,
  REQUEST_TYPE_OPTIONS,
  DEFAULT_REQUEST_OPTIONS,
  PREVIEW_ATTACHMENTS_OPTIONS
} from "../../utils/constants";
import { ConfigurationDataType, useConfigurationData } from "../../hooks/useConfigurationData";
import { store } from "../../state/store";
import { ActionType } from "../../state/actions";
import { AlertSeverity } from "../../constants/AlertTypes";
import PushbackCustomOptionsDialog from "../pushback/PushbackCustomOptionsDialog";

const ConfigMisc: React.FC = () => {

  const { saveConfigMiscData } = useActions();
  const [miscDefaultData, setMiscDefaultData] = useState<IDefaultValues | null>(null);
  const accessToken = useTypedSelector((state) => state.appSlice.accessToken);
  // Misc state
  const [selectedConfigId, setSelectedConfigId] = useState<string>("");
  const [requestUri, setRequestUri] = useState("");
  const [pluginUri, setPluginUri] = useState("");
  const [tokenUri, setTokenUri] = useState("");
  const [saving, setSaving] = useState<boolean>(false);
  const [pushbackOptionsDialogOpen, setPushbackOptionsDialogOpen] = useState(false);

  // State Confirmations
  const [alertOpen, setAlertOpen] = useState(false);
  const [saveError, setSaveError] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<AlertSeverity>('info');

  const hasFetchedData = useRef(false);
  const { data, error, loading } = useConfigurationData(ConfigurationDataType.DEFAULT_VALUES);
  const mData = useMemo(() => data, [data]);

  useEffect(() => {
    if (!hasFetchedData.current && data) {
      const configId = EncompassService.getConfigId() as string;
      setSelectedConfigId(configId);
      setMiscDefaultData(mData);
      initializeScreenData();
      hasFetchedData.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // Handling Saving Error 
  useEffect(() => {
    if (!saveError && data !== null) {
      setMiscDefaultData(mData);
      initializeScreenData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, saveError, mData]);

  const saveMiscConfigData = useCallback(
    async (configId: string, data: IDefaultValues) => {
      return await saveConfigMiscData(configId, data, accessToken);
    },
    [saveConfigMiscData, accessToken]
  );

  useEffect(() => {
    if (error) {
      openAlert(error, 'error');
    }
  }, [error])

  useEffect(() => {
    if (miscDefaultData) {
      saveState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [miscDefaultData]);

  const updateMiscData = (key: string, value: any) => {
    setMiscDefaultData((prevData) => {
      if (!prevData) return null;
      const newData = { ...prevData };
      if (key === "RequestTypeAvailability" && value !== REQUEST_TYPES.BOTH) {
        newData.DefaultRequestType = value;
      }
      return {
        ...newData,
        [key]: value,
      };
    });
  };


  function cacheEndpoints() {
    localStorage.setItem("requestUri", requestUri);
    localStorage.setItem("pluginUri", pluginUri);
    localStorage.setItem("tokenUri", tokenUri);
  }

  function initializeScreenData() {
    setRequestUri(
      localStorage.getItem("requestUri") ||
      (EncompassService.getRequestEndpoint() as string)
    );
    setPluginUri(
      (EncompassService.getPluginEndpoint() as string)
    );
    setTokenUri(
      localStorage.getItem("tokenUri") ||
      (EncompassService.getTokenEndpoint() as string)
    );
    if (!selectedConfigId) {
      setSelectedConfigId(getConfigId());
    }
  }

  const getConfigId = () => {
    if (selectedConfigId) return selectedConfigId;
    return EncompassService.getConfigId() as string;
  };

  // Call this to save local session redux state
  const saveState = () => {
    try {
      if (!miscDefaultData) return;
      const configPlugInData = store.getState().configPlugInData.data;
      const updatedConfigPlugInData = {
        ...configPlugInData,
        Configuration: {
          ...configPlugInData?.Configuration,
          DefaultValues: miscDefaultData,
        },
      };
      store.dispatch({
        type: ActionType.SAVE_CONFIG_PLUGIN_DATA_SUCCESS,
        payload: updatedConfigPlugInData
      });

    } catch (e) {
      console.log("Error Saving State: ", e);
      openAlert(`Error Saving State: ${e}`, 'error');
    }
  }

  const save = async () => {
    cacheEndpoints();
    try {
      setSaving(true);
      await saveMiscConfigData(getConfigId(), miscDefaultData as IDefaultValues)
      openAlert("Successfully Saved Changes", 'success');
      setSaveError(false);
    } catch (e) {
      setSaveError(true);
    } finally {
      setSaving(false);
    }
  };

  const handleSnackBarClose = (
    _event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
    setSaveError(false);
  };

  const handleEncompassUIIntegration = () => {
    alert("TBD: Open Encompass UI Integration");
  }

  const handlePushbackFieldMappings = () => {
    setPushbackOptionsDialogOpen(true);
  }



  const openAlert = (message: string, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(true);
  }

  return (
    <section>
      <div>
        {(loading || saving) && <ConfigLoad loading={loading} />}
      </div>
      <Box
        mt={1}
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          overflow: "auto",
          minHeight: "100vh",
          margin: "2px",
        }}
      >
        <Box display="flex" justifyContent="flex-end">
          <Button
            onClick={handleEncompassUIIntegration}
            variant='outlined'
            color={'primary'}
            size='small'
            sx={{ marginRight: "10px" }}
          >
            Encompass UI Integration
          </Button>
          <Button
            onClick={handlePushbackFieldMappings}
            variant='outlined'
            color={'primary'}
            size='small'
          >
            Pushback Field Mappings
          </Button>

        </Box>
        <Card sx={{ margin: "10px" }}>
          <CardContent>
            <Typography variant="h5" component="div">
              Misc Configuration
            </Typography>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Request</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6}>
                    <Typography>SaveLoanBeforeClosing</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={miscDefaultData?.SaveLoanBeforeDisclosing || ""}
                      onChange={(event) =>
                        updateMiscData(
                          "SaveLoanBeforeDisclosing",
                          event.target.value
                        )
                      }
                    >
                      {SAVE_LOAN_OPTIONS.map((option) => (
                        <MenuItem key={option.value} value={option.value} sx={{ padding: "2px 8px" }}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Request Type</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={miscDefaultData?.RequestTypeAvailability || ""}
                      onChange={(event) =>
                        updateMiscData(
                          "RequestTypeAvailability",
                          event.target.value
                        )
                      }
                    >
                      {REQUEST_TYPE_OPTIONS.map((option) => (
                        <MenuItem key={option.value} value={option.value} sx={{ padding: "2px 8px" }}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Default Request</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      fullWidth
                      variant="outlined"
                      size="small"
                      disabled={miscDefaultData?.RequestTypeAvailability !== REQUEST_TYPES.BOTH}
                      value={miscDefaultData?.RequestTypeAvailability === REQUEST_TYPES.BOTH ? miscDefaultData?.DefaultRequestType || "" : miscDefaultData?.RequestTypeAvailability || ""}
                      onChange={(event) =>
                        updateMiscData("DefaultRequestType", event.target.value)
                      }
                    >
                      {DEFAULT_REQUEST_OPTIONS.map((option) => (
                        <MenuItem key={option.value} value={option.value} sx={{ padding: "2px 8px" }}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Response</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6}>
                    <Typography>PreviewAttachmentsAlways</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={
                        miscDefaultData?.PreviewAttachmentsAlways
                          ? "true"
                          : "false"
                      }
                      onChange={(event) =>
                        updateMiscData(
                          "PreviewAttachmentsAlways",
                          event.target.value === "true"
                        )
                      }
                    >
                      {PREVIEW_ATTACHMENTS_OPTIONS.map((option) => (
                        <MenuItem key={option.value} value={option.value} sx={{ padding: "2px 8px" }}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <PushbackCustomOptionsDialog
              open={pushbackOptionsDialogOpen}
              closeFxtn={() => setPushbackOptionsDialogOpen(false)}
            />
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={alertOpen}
              autoHideDuration={4000}
              onClose={handleSnackBarClose}
            >
              <MuiAlert
                onClose={handleSnackBarClose}
                severity={alertSeverity}
                sx={{ width: '100%', fontSize: '1.2rem', padding: '12px 16px' }}>
                {alertMessage}
              </MuiAlert>
            </Snackbar>
          </CardContent>
        </Card>
        <ConfigBottomBar save={save} />
      </Box>
    </section>
  );
};

export default ConfigMisc;