import OriginService from '../services/originService';
import { useActions } from './useActions';

const useBuildAppStart = () => {
  const origin = new OriginService();
  const { getConfigPlugInData, getConfigServerData } = useActions();

  const buildAppStart = async (refreshOrigin: boolean) => {
    const originResponse = await origin.buildOrigin(refreshOrigin);

    if (originResponse.data.result.oAuthToken.accessToken && originResponse.data.result.configId) {
      if (originResponse.data.result.configId !== localStorage.getItem("configurationId")) {
        localStorage.setItem(
          "configurationId",
          originResponse.data.result.configId
        );
      }

      await getConfigPlugInData(
        originResponse.data.result.configId,
        originResponse.data.result.oAuthToken.accessToken,
        true
      );
      await getConfigServerData(
        originResponse.data.result.configId,
        originResponse.data.result.oAuthToken.accessToken
      );
    } else {
      // Handle the else condition by throwing an error
      throw new Error("Missing oAuthToken or configId in the response");
    }
  };

  return buildAppStart;
};

export default useBuildAppStart;