import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useActions } from "../../../hooks/useActions";
import MuiAlert from '@mui/material/Alert';
import { Box, Button, Snackbar, Tab, Tabs } from "@mui/material";
import DTDataGrid from "../../../components/shared/config/DTDataGrid";
import OtherDocumentBundling from "./OtherDocumentBundling";
import { IDocumentBundle, IEventBundle } from "../../../models/configuration/bundles/IBundles";
import ConfigLoad from "../../../components/shared/config/ConfigLoad";
import { RootState } from "../../../state/store";
import { useSelector } from "react-redux";
import EncompassService from "../../../services/encompassService";
import ConfigDocumentHandlingDialog from "./ConfigDocumentHandlingDialog";
import ConfirmationDialog from "../../../components/shared/ConfirmDialog";
import ConfigTopBar from "../../../components/shared/config/ConfigTopBar";
import "../../../assets/style.css";
import ConfigBottomBar from "../../../components/shared/config/ConfigBottomBar";
import DisclosureTrackingMappingsDialog from "./DisclosureTrackingMappingDialog";
import { IDisclosureTrackingMapping } from "../../../models/configuration/plugin/IConfiguration";
import DocumentMappingDialog from "./DocumentMappingDialog/DocumentMappingDialog";
import { signedDocColumns, unsignedDocColumns } from "./configbundlingdata";
import { AlertSeverity } from "../../../constants/AlertTypes";

const ConfigBundling: React.FC = () => {
    const [tabValue, setTabValue] = useState(0);

    // Reducers
    const {
        getConfigBundleDocumentData,
        saveConfigBundleData,
        saveConfigDisclosureTrackingMapping,
        saveConfigBundleEventData } = useActions();
    const accessToken = useSelector((state: RootState) => state.appSlice.accessToken);
    const { data, error, loading } = useTypedSelector((state) => state.configDocumentBundles);
    const [saving, setSaving] = useState<boolean>(false);
    const [selectedConfigId, setSelectedConfigId] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [disclosureTrackingMappingData, setDisclosureTrackingMappingData] = useState<IDisclosureTrackingMapping | null>(null);

    // State
    const [unsignedDocBundles, setUnsignedDocBundles] = useState<Array<IDocumentBundle>>([]);
    const [signedDocBundles, setSignedDocBundles] = useState<Array<IDocumentBundle>>([]);
    const [otherDocEventBundles, setOtherDocEventBundles] = useState<Array<IEventBundle>>([]);
    const [selectedBundle, setSelectedBundle] = useState<IDocumentBundle | null>(null);
    const [editingBundle, setEditingBundle] = useState<boolean>(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [currentSelectedBundle, setCurrentSelectedBundle] = useState<IDocumentBundle | null>(null);
    const [disclosureTrackingMappingDialogOpen, setDisclosureTrackingMappingsDialogOpen] = useState(false);
    const [showDocumentMapping, setShowDocumentMapping] = useState(false);

    // State Confirmations
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState<AlertSeverity>('info');

    const UNSIGNED = "unsigned";
    const SIGNED = "signed";

    // Refs
    const hasFetched = useRef(false);

    const fetchBundles = useCallback((configId: string) => {
        getConfigBundleDocumentData(configId, accessToken);
    }, [getConfigBundleDocumentData, accessToken]);

    useEffect(() => {

        if (!hasFetched.current) {
            const configId = EncompassService.getConfigId() as string;
            setSelectedConfigId(configId);
            fetchBundles(configId);
            hasFetched.current = true;
        }
    }, [fetchBundles]);

    useEffect(() => {
        if (data != null) {
            setUnsignedDocBundles(
                JSON.parse(JSON.stringify(data.Configuration.Bundling.filter(
                    (x) => x.BundlingConfigurationType?.toLowerCase() === UNSIGNED
                )))
            );
            setSignedDocBundles(
                JSON.parse(JSON.stringify(data.Configuration.Bundling.filter(
                    (x) => x.BundlingConfigurationType?.toLowerCase() === SIGNED
                )))
            );
        }
    }, [data]);

    useEffect(() => {
        if (selectedBundle) {
            const updatedBundles = selectedBundle.BundlingConfigurationType?.toLowerCase() === UNSIGNED
                ? unsignedDocBundles.map(bundle => bundle.PackageId === selectedBundle.PackageId ? { ...selectedBundle } : bundle)
                : signedDocBundles.map(bundle => bundle.PackageId === selectedBundle.PackageId ? { ...selectedBundle } : bundle);
            if (selectedBundle.BundlingConfigurationType?.toLowerCase() === UNSIGNED) {
                // compare state to ensure there was a change
                if (JSON.stringify(unsignedDocBundles) !== JSON.stringify(updatedBundles)) {
                    setUnsignedDocBundles(updatedBundles);
                }
            } else {
                if (JSON.stringify(signedDocBundles) !== JSON.stringify(updatedBundles)) {
                    setSignedDocBundles(updatedBundles);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBundle]);

    function editItem(e: any, item: any) {
        setSelectedBundle(item);
        setEditingBundle(true);
    }

    function deleteItem(e: any, item: any) {
        setCurrentSelectedBundle(item);
        setConfirmDialogOpen(true);
    }

    function deleteBundle(item: any) {
        let docindex = unsignedDocBundles.findIndex(bundle => bundle.PackageId === item.PackageId);
        if (docindex > -1) {
            setUnsignedDocBundles([
                ...unsignedDocBundles.slice(0, docindex),
                ...unsignedDocBundles.slice(docindex + 1),
            ]);
        } else {
            docindex = signedDocBundles.findIndex(bundle => bundle.PackageId === item.PackageId);
            if (docindex > -1) {
                setSignedDocBundles([
                    ...signedDocBundles.slice(0, docindex),
                    ...signedDocBundles.slice(docindex + 1),
                ]);
            }
        }

        setCurrentSelectedBundle(null);
        setConfirmDialogOpen(false);
    }

    const updateOtherDocumentEventBundles = (eventBundles: Array<IEventBundle>) => {
        setOtherDocEventBundles(eventBundles);
    };

    function cancelDeleteBundle() {
        setConfirmDialogOpen(false);
        setCurrentSelectedBundle(null);
    }

    const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const saveBundles = async () => {
        const combinedDocBundles = JSON.parse(JSON.stringify([...unsignedDocBundles, ...signedDocBundles])); // Deep copy
        await saveConfigBundleData(selectedConfigId, combinedDocBundles, accessToken);
    }

    const saveDisclosureTrackingMapping = async () => {
        if (disclosureTrackingMappingData) {
            const deepCopyData = JSON.parse(JSON.stringify(disclosureTrackingMappingData)); // Deep copy
            await saveConfigDisclosureTrackingMapping(selectedConfigId, deepCopyData, accessToken);
        }
    }

    const saveOtherDocEventBundles = async () => {
        if (otherDocEventBundles.length > 0) {
            const deepCopyBundles = JSON.parse(JSON.stringify(otherDocEventBundles)); // Deep copy
            await saveConfigBundleEventData(selectedConfigId, deepCopyBundles, accessToken);
        }
    }

    const save = async () => {
        try {
            setSaving(true);
            await Promise.all([saveBundles(), saveDisclosureTrackingMapping(), saveOtherDocEventBundles()])
                .then(() => {
                    openAlert("Successfully Saved Changes.", 'success');
                    setErrorMessage('');
                })
                .catch((error) => {
                    console.error("Error saving data", error);
                    setErrorMessage(error instanceof Error ? `Failed to save data: ${error.message}` : 'Failed to save data: Unknown error');
                    openAlert("Failed to Save Changes", 'error');
                });


        } catch (e) {
            console.error("Error saving data", e);
            setErrorMessage(e instanceof Error ? `Failed to save data: ${e.message}` : 'Failed to save data: Unknown error');
            openAlert("Failed to Save Changes", 'error');
        } finally {
            setSaving(false);
        }
    }

    // Alert] Handling 
    const handleSnackBarClose = () => setAlertOpen(false);
    const openAlert = (message: string, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
        setAlertSeverity(severity);
        setAlertMessage(message);
        setAlertOpen(true);
    };
    function openDisclosureTrackingMappings() { setDisclosureTrackingMappingsDialogOpen(true); }
    function closeDisclosureTrackingMappings() { setDisclosureTrackingMappingsDialogOpen(false); }
    function updateDisclosureTrackingMappingData(data: IDisclosureTrackingMapping | null) {
        if (data) {
            setDisclosureTrackingMappingData(data);
        }
    }

    return (
        <>
            <section>
                <div>
                    <div>
                        <ConfigLoad loading={loading || saving} />
                    </div>
                </div>

                <Box
                    component="main"
                    sx={{
                        m: 2,
                        p: 2,
                        backgroundColor: (theme) =>
                            theme.palette.mode === "light"
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        overflow: "auto",
                        height: "100%",
                    }}
                >
                    <ConfigTopBar save={save} error={error || errorMessage || null} />
                    <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1, flexGrow: 1, alignItems: 'center' }}>
                        <Button
                            onClick={() => { setShowDocumentMapping(true); }}
                            color={'secondary'}
                            variant='outlined'
                            size='small'
                        >
                            Document Mappings
                        </Button>
                        <DisclosureTrackingMappingsDialog
                            open={disclosureTrackingMappingDialogOpen}
                            closeFxtn={closeDisclosureTrackingMappings}
                            updateData={updateDisclosureTrackingMappingData}
                        />

                        <Button
                            onClick={openDisclosureTrackingMappings}
                            color={'secondary'}
                            variant='outlined'
                            size='small'
                        >
                            Disclosure Tracking Mappings
                        </Button>
                    </Box>

                    <>

                        <Tabs value={tabValue} onChange={handleTabChange}>
                            <Tab label="Unsigned Documents" {...docPanelProps(0)} />
                            <Tab label="Signed Documents" {...docPanelProps(1)} />
                            <Tab label="Other Documents" {...docPanelProps(2)} />
                        </Tabs>

                        <DocsTabPanel value={tabValue} index={0}>
                            <DTDataGrid
                                data={unsignedDocBundles}
                                columns={unsignedDocColumns(editItem, deleteItem)}
                                tableName="unsigneddocs"
                                containerSx={{ maxHeight: "60vh" }}
                            />
                        </DocsTabPanel>

                        <DocsTabPanel value={tabValue} index={1}>
                            <DTDataGrid
                                data={signedDocBundles}
                                columns={signedDocColumns(editItem, deleteItem)}
                                tableName="signeddocs"
                                containerSx={{ maxHeight: "60vh" }}
                            />
                        </DocsTabPanel>

                        <DocsTabPanel value={tabValue} index={2}>
                            <OtherDocumentBundling
                                updateOtherDocuments={updateOtherDocumentEventBundles}
                            />
                        </DocsTabPanel>

                    </>
                    <ConfigBottomBar save={save} />
                </Box>
            </section>
            {selectedBundle && editingBundle}
            {
                <ConfigDocumentHandlingDialog
                    open={editingBundle}
                    documentBundle={selectedBundle!}
                    updateBundle={setSelectedBundle}
                    closeFxtn={() => setEditingBundle(false)}
                />
            }

            <ConfirmationDialog
                open={confirmDialogOpen}
                title="Delete Configuration"
                cancelName="No"
                confirmName="Yes"
                message={`Are you sure you want to delete the  '${currentSelectedBundle?.BundlingConfigurationType}' Bundling Configuration for '${currentSelectedBundle?.PackageId}'?`}
                onConfirm={() => deleteBundle(currentSelectedBundle)}
                onCancel={() => cancelDeleteBundle()}
            />

            <DocumentMappingDialog
                open={showDocumentMapping}
                closeFxtn={() => setShowDocumentMapping(false)}
            />

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={alertOpen}
                autoHideDuration={4000}
                onClose={handleSnackBarClose}
            >
                <MuiAlert
                    onClose={handleSnackBarClose}
                    severity={alertSeverity}
                    sx={{ width: '100%', fontSize: '1.2rem', padding: '12px 16px' }}>
                    {alertMessage}
                </MuiAlert>
            </Snackbar>
        </>
    );
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function DocsTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`bundle-tabpanel-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 1, height: 1 }}>{children}</Box>}
        </div>
    );
}

function docPanelProps(index: number) {
    return {
        id: `bundle-tab-${index}`,
    };
}

export default ConfigBundling;