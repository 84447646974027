import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { FieldMapping } from '../../models/configuration/xpathFieldMap/IXpathFieldMap';
import XPathValidator from '../shared/XPathValidator';

interface XPathGridItemEditDialogProps {
    open: boolean;
    closeFxtn: () => void;
    title?: string | undefined;
    item: FieldMapping | null;
    updateFxtn: (item: FieldMapping) => void;
}

const XPathGridItemEditDialog: React.FC<XPathGridItemEditDialogProps> = ({ open, closeFxtn, item, title, updateFxtn }) => {

    const [itemState, setItemState] = useState<FieldMapping | null>(item);
    const [_isValid, setIsValid] = useState<boolean>(false);

    const closeDialog = () => { closeFxtn(); }

    useEffect(() => {
        setItemState(item);
    }, [item]);

    const updateItem = () => {
        if (!itemState)
            return;
        updateFxtn(itemState!);
    }

    const updateAndClose = () => {
        updateItem();
        closeDialog();
    }

    const validateXPath = (result: boolean) => { setIsValid(result); }

    const handleXPathChange = (xpath: string) => {
        if (xpath === "")
            return;
        setItemState({ ...itemState!, Xpath: xpath });
    }

    return (
        <Dialog open={open} onClose={closeDialog}
            sx={{ width: '100%', height: '100%' }}
        >
            <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                <span>{title ?? "Title"}</span>
                <IconButton
                    aria-label="close"
                    onClick={closeDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            gap: 1,
                            alignItems: "center",
                            height: "100%",
                        }}
                    >
                        <Box sx={{ fontWeight: "bold", pr: 1 }}>Description:</Box>
                        <TextField
                            onChange={(e) => setItemState({ ...itemState!, Name: e.target.value })}
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={itemState?.Name}
                            sx={{ flex: 1, width: "350px" }}
                        />
                        <Box sx={{ fontWeight: "bold", pr: 1 }}>Output Encompass Path:</Box>
                        <TextField
                            onChange={(e) => setItemState({ ...itemState!, EncompassFieldId: e.target.value })}
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={itemState?.EncompassFieldId}
                            sx={{ flex: 1, width: "350px" }}
                        />
                    </Box>
                    <Box>
                        <XPathValidator
                            xPath={itemState?.Xpath ?? ""}
                            resultFxtn={validateXPath}
                            updateXPathFxtn={handleXPathChange}
                        />
                    </Box>

                    <Stack direction="row" spacing={2} justifyContent="flex-end">
                        <Button variant="contained" onClick={updateItem}>Save</Button>
                        <Button variant="contained" onClick={updateAndClose}>Save and Close</Button>
                        <Button variant="contained" onClick={closeDialog}>Close</Button>
                    </Stack>

                </Stack>
            </DialogContent>
        </Dialog>
    );
}

export default XPathGridItemEditDialog;