import React, { useEffect, useState } from 'react';
import { Card, CardContent, Tab, Tabs } from '@mui/material';
import CustomTabPanel from '../shared/CustomTabPanel';
import DTDataGrid from "../../components/shared/config/DTDataGrid";
import { IExpression, IPackage } from "../../models/configuration/plugin/IConfiguration";
import { evaluationResultsColumns, validDeliveryMethodsColumns } from "./EvaluationResultsData";
import { SpecialDeliveryTypes, FullfillmentShippingOptions, SpecialClosingTypes } from "../../enums/SpecialDeliveryTypes";
import { IControlEvaluationResult, IExpressionValidationResult } from '../../models/IControlEvaluationResult';
import expressionService from '../../services/expressionService';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import GenericDialog from '../shared/GenericDialog';
import ExpressionEditor from '../shared/config/ExpressionEditor';
import { ExpressionValidationResult } from '../../utils/expressionHelper';

// TODO: SUMA here is a list of todos on this page 
// TODO: 1. A better unique idenfieir on the viewExpression... like we discussed in the meeting 
// TODO: 2. Disable the view expression link on the grid if no expresions exist
// TODO: 3. ShowALL Button
// TODO: 4. I also added some comments around those area down below as well. 

interface EvaluationResultsProps {
    packageList: IPackage[] | undefined;
}

const initialExpressionState: IExpression = {
    ExpressionName: "",
    ExpressionString: "",
    ExpressionMessage: "",
    Enabled: false
}

const EvaluationResults: React.FC<EvaluationResultsProps> = ({ packageList }) => {
    const expService = new expressionService();
    const [tabValue, setTabValue] = React.useState(0);
    //TODO: Pending Tab0 due to expression dependency

    const [evaluationResults, setEvaluationResults] = useState<Array<IControlEvaluationResult>>([]);
    const [validDeliveryMethods, setValidDeliveryMethods] = useState<Array<IPackage>>([]);
    const accessToken = useSelector((state: RootState) => state.appSlice.accessToken);
    const [selectedExpression, setSelectedExpression] = useState<IExpression>(initialExpressionState);
    const [openExpression, setOpenExpression] = useState(false);

    useEffect(() => {
        if (packageList != null) {
            handleEvaluationResults(packageList);

            // Handle Valid Delivery Methods
            const packageUpdated = getUpdatedPackageId(packageList);
            // Creating a shallow copy to avoid mutation
            let updatedPackageList: IPackage[] = [...packageUpdated]
            packageList = updatedPackageList.map((pkg: IPackage) => {
                return { ...pkg, ValidDeliveryTypes: AddSpecialDeliveryTypes(pkg.ValidDeliveryTypes) };
            })
            setValidDeliveryMethods(packageList);
        }
    }, [packageList]);

    const handleEvaluationResults = async (packageList: IPackage[]) => {

        const evaluationList = Array<IControlEvaluationResult>();
        const evaluationPromises = packageList
            .filter((pkg1) => pkg1.Enabled)
            .map(async (pkg) => {
                if (pkg.Expressions == null || pkg.Expressions.length === 0) {
                    const evaluation: IControlEvaluationResult = {
                        id: pkg.Id,
                        group: "Security",
                        message: "Security Access",
                        result: true,
                        controlType: "Package",
                        expressionIdentifier: null
                    };
                    evaluationList.push(evaluation);
                } else {
                    let results = await ValidationExpressions(pkg.Expressions);
                    if (results && results.result.length > 0) {
                        results.result.forEach((exp) => {
                            const result: IControlEvaluationResult = {
                                id: pkg.Id,
                                group: "Expression",
                                message: exp.error,
                                result: exp.evaluationResult,
                                controlType: "Package",
                                expressionIdentifier: exp.expresion
                            };
                            evaluationList.push(result);
                        });
                    }
                }
            });

        await Promise.all(evaluationPromises);
        setEvaluationResults(evaluationList);
    }

    const ValidationExpressions = async (expressions: IExpression[]): Promise<IExpressionValidationResult> => {
        const expressionArray = expressions.map(e => e.ExpressionMessage);
        return await expService.validateExpressions(accessToken, expressionArray);
    };

    const getUpdatedPackageId = (pkgList: IPackage[]): IPackage[] => {
        return pkgList.map((pkg: IPackage) => {
            return {
                ...pkg,
                Id: (pkg.ParentPackageId == null || pkg.ParentPackageId === undefined || pkg.ParentPackageId === "") ? pkg.Id.trim() :
                    pkg.AlternatePackageId?.trim() + " [" + pkg.Id.trim() + "]"
            }
        })
    }

    const AddSpecialDeliveryTypes = (deliveryTypes: string[]) => {
        let updatedDeliveryTypes = [...deliveryTypes] //avoid mutating the array

        //Adding custom delivery
        updatedDeliveryTypes.push(SpecialDeliveryTypes.PrintInPerson);

        //Adding special delivery types for FullFillmentShippingOptions
        if (updatedDeliveryTypes.includes("Mail")) {
            for (let deliveryType in FullfillmentShippingOptions) {
                updatedDeliveryTypes.push(`Mail-${deliveryType}`)
            }
        }

        //Adding special delivery types for SpecialClosingTypes
        if (updatedDeliveryTypes.includes("eClose")) {
            for (let deliveryType in SpecialClosingTypes) {
                updatedDeliveryTypes.push(`eClose-${deliveryType}`)
            }
        }

        updatedDeliveryTypes.some(pkg => pkg.startsWith("eSign")) ?? updatedDeliveryTypes.push(SpecialDeliveryTypes.ESignSecondaryId);
        updatedDeliveryTypes.some(pkg => pkg.startsWith("eDisclose")) ?? updatedDeliveryTypes.push(SpecialDeliveryTypes.EDsicloseSecondaryId);
        updatedDeliveryTypes.some(pkg => pkg.startsWith("eClose")) ?? updatedDeliveryTypes.push(SpecialDeliveryTypes.ECloseSecondaryId);

        return updatedDeliveryTypes;
    }

    // TODO: This needs to also call the validator 
    const validateEditorExpression = (expression: IExpression): ExpressionValidationResult | null => {
        alert("To be done");
        return null;
    }

    // TODO: SUMA
    // TODO: this takes the grid values and using the expressionIdentifier it locates the actual expression in the package
    // TODO: the way this is done is not ideal since it's using the expression string as the identifier (Maybe a unique identifier) 
    // TODO: Once the expression is found it loads it into the Expression Validator Window
    // TODO: I Did it straight from the grid since it made more sense. 
    const viewExpression = (e: any, expressionEval: IControlEvaluationResult) => {
        const matchingPackages = packageList?.filter(x => x.Expressions && x.Expressions.length > 0 && x.Id === expressionEval.id);

        if (!matchingPackages || matchingPackages.length === 0) {
            return;
        }

        let foundExpression = null;
        for (const pkg of matchingPackages) {

            foundExpression = pkg.Expressions?.find(exp =>
                exp.ExpressionString.trim() === expressionEval.expressionIdentifier?.trim()
            );

            if (foundExpression)
                break;
        };

        if (foundExpression) {
            setSelectedExpression(foundExpression);
            setOpenExpression(true);
        }
    };


    const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    function tabProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }



    return (
        <div>
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 2
                }}
            >
                <CardContent>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="Evaluations" {...tabProps(0)} />
                        <Tab label="Valid Delivery Methods" {...tabProps(1)} />
                    </Tabs>
                    <CustomTabPanel value={tabValue} index={0}>
                        <DTDataGrid
                            data={evaluationResults}
                            columns={evaluationResultsColumns(viewExpression)}
                            tableName="Test"
                            containerSx={{ maxHeight: "60vh" }}
                        />
                    </CustomTabPanel>

                    <CustomTabPanel value={tabValue} index={1}>
                        <DTDataGrid
                            data={validDeliveryMethods}
                            columns={validDeliveryMethodsColumns()}
                            tableName="ValidDeliveryMethods"
                            containerSx={{ maxHeight: "60vh" }}
                        />
                    </CustomTabPanel>
                </CardContent>
                <GenericDialog
                    open={openExpression}
                    onClose={() => setOpenExpression(false)}
                    title="Expression Editor"
                >
                    <ExpressionEditor
                        expression={selectedExpression}
                        onValidate={validateEditorExpression}
                        editorMode={""}
                    />
                </GenericDialog>
            </Card>
        </div>
    );
};

export default EvaluationResults;