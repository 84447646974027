import axios, { AxiosRequestConfig } from "axios";
import ApiService from "./apiService";
import EncompassService from "./encompassService";
import AuthService from "./authService";
import { IExpressionValidationResult } from "../models/IControlEvaluationResult";

// TODO: SUMA
// TODO: Again, this may be duplicated and as we discussed this call into the proxy as well. 
// TODO: Also, uses the new EncompassService.getExpressionValidationEndpoint() which Arun Supplied.

class expressionService {

    private apiService: ApiService;
    private baseUrl: string;

    constructor() {
        this.baseUrl = EncompassService.getExpressionValidationEndpoint() as string;
        this.apiService = new ApiService(this.baseUrl);
    }

    public async validateExpressions(token: string | null, expressions: Array<string>): Promise<IExpressionValidationResult> {

        try {
            const route = "evaluate";

            if (!expressions || expressions?.length === 0) {
                throw new Error("Validate Expressions Requires and Array of expressions")
            }

            const config: AxiosRequestConfig = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }

            const body = {
                expressions: expressions
            }

            let response;
            if (token === null) {
                const urlRoute = `${this.baseUrl}/${route}?token=${encodeURIComponent((await new AuthService().getToken()).access_token as string)}`;
                response = await axios.post(urlRoute, body);
            } else {
                const requestBody = JSON.stringify(body);
                response = await this.apiService.post(`/${route}`, requestBody, config);
            }
            return response.data;
        } catch (error) {
            console.error('Error Saving Config:', error);
            throw error;
        }
    }
}

export default expressionService; 