import { PRESIGN_API_OBJECTS } from "../utils/constants";
import PresignUrlApiService from "./docGenApiService";
import { store } from "../state/store"
import { setLoading } from "../state/appSlice";
import JSZip from "jszip";
import ElliHost from "./elliHost";

class PreviewDocsService {

    private elliHost;
    constructor() {
        this.elliHost = ElliHost.getInstance();
    }

    public async downloadMetadata(transactionId: string | null, authToken: string, requests: any) {
        store.dispatch(setLoading(true));
        const service = new PresignUrlApiService();
        const response = await service.getPresignResponse(transactionId ?? requests[0].RequestId, authToken, [PRESIGN_API_OBJECTS.METADATA]);
        if (response.result.signedUrls && response.result.signedUrls['metadata.zip']) {
            //download metadata.zip
            await fetch(response.result.signedUrls['metadata.zip'], {
                method: 'GET',
                headers: {
                    'x-bearer-auth': authToken
                }
            })
                .then(async (response) => {
                    if (response.ok) {
                        const blob = await response.blob()
                        if (blob) {
                            const url = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', 'metadata.zip');
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode?.removeChild(link);
                        }
                    }
                    else {
                        console.log("Error fetching metadata.zip", response.status)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    store.dispatch(setLoading(false));
                })
        }
    }

    public async previewDocument(transactionId: string | null, authToken: string, requests: any) {
        store.dispatch(setLoading(true));

        const service = new PresignUrlApiService();
        const response = await service.getPresignResponse(transactionId ?? requests[0].RequestId, authToken, [PRESIGN_API_OBJECTS.PDF_PACKAGE]);

        if (response.result.signedUrls && response.result.signedUrls['package.pdf']) {
            // Preview pdf 
            this.elliHost.openResource({ target: response.result.signedUrls['package.pdf'] })
                .catch((error) => {
                    console.error("Error opening resource in modal:", error);
                })
                .finally(() => {
                    store.dispatch(setLoading(false));
                });
        } else {
            console.log("Error fetching package.pdf");
            store.dispatch(setLoading(false));
        }
    }
}

export default PreviewDocsService;