import dayjs from "dayjs";
import { IClientLog } from "../models/configuration/pushback/IPushbackReport";
import { DTDataGridAlignment, DTDataGridColumnType, IDataColumn } from "../models/IDataColumn";
import { FieldMapping, IXpathFieldMap } from "../models/configuration/xpathFieldMap/IXpathFieldMap";

export const clientLogs = (
): Array<IDataColumn> => {
    return [
        {
            name: "LogTime",
            fieldId: "LoggedTimestamp",
            datatype: DTDataGridColumnType.text,
            sortable: true,
            dataFunction: (item: IClientLog) => {
                return dayjs(item.LoggedTimestamp).format('MM/DD/YYYY hh:mm:ss A Z');
            }
        },
        {
            name: "Severity",
            fieldId: "SeverityLevel",
            datatype: DTDataGridColumnType.text,
            sortable: true,
            foreColorFunction: (item: IClientLog) => {
                switch (item.SeverityLevel) {
                    case "Warning":
                        return "orange";
                    case "Complete":
                        return "green";
                    case "Alert":
                        return "red";
                    default:
                        return "black";
                }
            }
        },
        {
            name: "User",
            fieldId: "EncompassUser",
            datatype: DTDataGridColumnType.text,
            sortable: true
        },
        {
            name: "Message",
            fieldId: "Message",
            datatype: DTDataGridColumnType.text,
            sortable: true,
        }
    ];
};

export const xPathDataColumns = (
    editItem: (e: any, item: any) => void,
    deleteItem: (e: any, item: any) => void
): Array<IDataColumn> => {
    return [
        {
            name: "",
            fieldId: "",
            datatype: DTDataGridColumnType.link,
            linkText: "Edit",
            foreColor: "blue",
            callbackFunction: editItem,
            alignment: DTDataGridAlignment.center
        },
        {
            name: "",
            fieldId: "",
            datatype: DTDataGridColumnType.link,
            linkText: "Delete",
            foreColor: "firebrick",
            callbackFunction: deleteItem,
        },
        {
            name: "Mapping Name",
            fieldId: "Name",
            datatype: DTDataGridColumnType.text,
        },
        {
            name: "Output Encompass Path",
            fieldId: "EncompassFieldId",
            datatype: DTDataGridColumnType.text,
        },
        {
            name: "Input XPath",
            fieldId: "Xpath",
            datatype: DTDataGridColumnType.text,
        }
    ];
};