import { Button, Card, CardActions, CardContent, CardHeader, IconButton, List, ListItem, ListItemText } from "@mui/material"
import ElliHost from "../services/elliHost";
import { useDispatch } from "react-redux";
import { addAttachments, clearAttachments, removeAttachment, setLoading } from "../state/appSlice";
import { useTypedSelector } from "../hooks/useTypedSelector";
import DeleteIcon from '@mui/icons-material/Delete';
import { GenericCardProps } from "../models/GenericCardProps"; 
import { useEffect, useState } from "react";
 
const AttachmentsCard = (props: GenericCardProps) => { 

    const elliHost = ElliHost.getInstance();
    const dispatch = useDispatch();
    const attachments = useTypedSelector((state) => state.appSlice.attachments);

    const [enableClearButton, setEnableClearButton] = useState<boolean>(false);

    //setting clear button enabled
    useEffect(() => {
        if (attachments.length > 0) {
            setEnableClearButton(true)
        } else
            setEnableClearButton(false);
    }, [attachments])

    const openResourceUpload = async () => {
        dispatch(setLoading(true));
        const files = await elliHost.getResources();
        dispatch(setLoading(false));
        dispatch(addAttachments(files));
    }

    const handleAttachmentDelete = (id: string) => {
        dispatch(removeAttachment(id));
    }

    const handleClearAttachments = () => {
        dispatch(clearAttachments())
    }

    const cardVisibility = ()=>{
        if(props.visible)
            return 'flex';
        return 'none';
    }    

    return (
        <Card sx={{ p: 2, display: cardVisibility, flexDirection: 'column' }}>
            <CardHeader title={((props.title && props.title!== "")? props.title : "Attachments")} />
            <CardContent sx={{ overflow: 'auto', paddingTop: "0px", marginTop: "-15px" }}>
                <List dense={true}>
                    {attachments?.map((attachment) =>
                        <ListItem key={attachment?.id} secondaryAction={
                            <IconButton edge="end" aria-label="delete"
                                disabled={!props.enabled}
                                onClick={() => handleAttachmentDelete(attachment?.id)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        }>
                            <ListItemText
                                primary={attachment?.name}
                                secondary={attachment?.source}
                            />
                        </ListItem>
                    )}
                </List>
            </CardContent>
            <CardActions sx={{
                alignSelf: "stretch",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-start",
            }}>
                <Button variant="contained" size="small" onClick={openResourceUpload} disabled={!props.enabled}>
                    Browse and Attach
                </Button>
                <Button variant="contained" size="small" color="error" onClick={handleClearAttachments} disabled={!enableClearButton}>
                    Clear
                </Button>
            </CardActions>
        </Card>
    )
}

export default AttachmentsCard;