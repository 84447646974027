import dayjs from "dayjs";
import { IPushbackEntry, IPushbackOrderDetail } from "../../models/configuration/pushback/IPushbackLoans";
import { DTDataGridAlignment, DTDataGridColumnType, IDataColumn } from "../../models/IDataColumn";

export const orderDetailsColumns = (
    viewItem: (e: any, item: any) => void,
): Array<IDataColumn> => {
    return [
        {
            name: "Received",
            fieldId: "CreatedTimestamp",
            datatype: DTDataGridColumnType.text,
            sortable: true,
            dataFunction: (item: IPushbackOrderDetail) => { 
                return dayjs(item.CreatedTimestamp).format('MM/DD/YYYY hh:mm:ss A Z');
            }
        },
        {
            name: "PackageType",
            fieldId: "PackageType",
            datatype: DTDataGridColumnType.text,
            sortable: true
        },
        {
            name: "DeliveryTypes",
            fieldId: "Delivery Types",
            datatype: DTDataGridColumnType.text,
            sortable: true,
            dataFunction: (item: IPushbackOrderDetail) => {
                return item.DeliveryTypes.join(", ");
            }
        },
        {
            name: "User",
            fieldId: "EncompassUser",
            datatype: DTDataGridColumnType.text,
            sortable: true
        },
        {
            name: "View Events",
            fieldId: "",
            datatype: DTDataGridColumnType.link,
            sortable: false,
            linkText: "View",
            foreColor: "blue",
            callbackFunction: viewItem,
            alignment: DTDataGridAlignment.center
        },
    ];
};

export const pushBackDetailColumns = (
    viewItem: (e: any, item: any) => void,
): Array<IDataColumn> => {
    return [
        {
            name: "Received",
            fieldId: "CreatedTimestamp",
            datatype: DTDataGridColumnType.text,
            sortable: true,
            dataFunction: (item: IPushbackEntry) => { 
                return dayjs(item.CreatedTimestamp).format('MM/DD/YYYY hh:mm:ss A Z');
            }
        },
        {
            name: "Status",
            fieldId: "Status",
            datatype: DTDataGridColumnType.text,
            sortable: true,
            foreColorFunction: (item: IPushbackEntry) => {
                switch (item.Status) {
                    case "Warning":
                        return "orange";
                    case "Complete":
                        return "green";
                    case "Alert":
                        return "red";
                    default:
                        return "black";
                }
            }
        },
        {
            name: "LastStatusChange",
            fieldId: "LastStatusChangeTimestamp",
            datatype: DTDataGridColumnType.text,
            sortable: true,
            dataFunction: (item: IPushbackEntry) => { 
                return new Date(item.LastStatusChangeTimestamp).toLocaleString();
            }
        },
        {
            name: "Reason",
            fieldId: "Reason",
            datatype: DTDataGridColumnType.text,
            sortable: true
        },
        {
            name: "View Report",
            fieldId: "",
            datatype: DTDataGridColumnType.link,
            sortable: false,
            linkText: "View",
            foreColor: "blue",
            callbackFunction: viewItem,
            alignment: DTDataGridAlignment.center
        },
    ];
};

export const pushBackDetailColumnReport = (
): Array<IDataColumn> => {
    return [
        {
            name: "StartTime",
            fieldId: "CreatedTimestamp",
            datatype: DTDataGridColumnType.text,
            sortable: true,
            dataFunction: (item: IPushbackEntry) => { 
                return new Date(item.CreatedTimestamp).toLocaleString();
            }
        },
        {
            name: "EndTime",
            fieldId: "LastStatusChangeTimestamp",
            datatype: DTDataGridColumnType.text,
            sortable: true,
            dataFunction: (item: IPushbackEntry) => { 
                return new Date(item.LastStatusChangeTimestamp).toLocaleString();
            }
        },
        {
            name: "Status",
            fieldId: "Status",
            datatype: DTDataGridColumnType.text,
            sortable: true,
            foreColorFunction: (item: IPushbackEntry) => {
                switch (item.Status) {
                    case "Warning":
                        return "orange";
                    case "Complete":
                        return "green";
                    case "Alert":
                        return "red";
                    default:
                        return "black";
                }
            }
        },
        {
            name: "Message",
            fieldId: "Message",
            datatype: DTDataGridColumnType.text,
            sortable: true
        }
    ];
};