import { useEffect, useCallback, useRef } from "react";
import { useActions } from "./useActions";
import { useSelector } from "react-redux";
import { RootState } from "../state/store";
import EncompassService from "../services/encompassService";
import { useTypedSelector } from "./useTypedSelector";

function useXPathData() {

    const { getConfigXPathData } = useActions();
    const accessToken = useSelector((state: RootState) => state.appSlice.accessToken);
    const { data, error, loading } = useTypedSelector((state) => state.xPathData);

    // Refs
    const hasFetched = useRef(false);

    useEffect(() => {
        if (!hasFetched.current) {
            const configId = EncompassService.getConfigId() as string;
            const delayFetch = setTimeout(() => {
                fetchData(configId);
                hasFetched.current = true;
            }, 1000);

            return () => clearTimeout(delayFetch); // Cleanup timeout
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = useCallback((configId: string) => {
        getConfigXPathData(configId, accessToken);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getConfigXPathData]);

    if (loading || error || !hasFetched) {
        return null;
    }

    return data;
}

export { useXPathData };