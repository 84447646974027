import React, { } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import XPathGrid from '../../components/xpath/XPathGrid';

interface PushbackConfigOptionDialogProps {
    open: boolean;
    closeFxtn: () => void;
}

const PushbackCustomOptionsDialog: React.FC<PushbackConfigOptionDialogProps> = ({ open, closeFxtn }) => {

    const closeDialog = () => {
        closeFxtn();
    }

    return (
        <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="lg">
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>Pushback Customization Configuration Options</span>
                <IconButton
                    aria-label="close"
                    onClick={closeDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8, 
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box>
                    <XPathGrid />
                </Box>
                <Box
                sx={{
                    display: 'flex',
                    justifyContent:'flex-end'
                }}>
                <Button variant="contained" onClick={closeDialog} >Close</Button>
            </Box>
            </DialogContent>
        </Dialog>
    );
}

export default PushbackCustomOptionsDialog;