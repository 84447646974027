import React from "react";
import { Card, CardContent, Checkbox, FormControlLabel, Grid, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { IOtherControl } from "../../../models/configuration/plugin/IConfiguration";

interface controlDetailProps {
    selectedControl: IOtherControl | null,
    handleOnEnabledChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleOnBehaviorChange: (event: SelectChangeEvent) => void,
    handleOnCaptionChange:(event:  React.ChangeEvent<HTMLInputElement>) => void,
}

const ControlDetails : React.FC<controlDetailProps> =
    ({ selectedControl, handleOnEnabledChange, handleOnBehaviorChange, handleOnCaptionChange  }) => {

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                margin: 2,
                height: '75px', 
                width: '100%',
            }}
        >
            <CardContent>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems:"center"  }}>
                    <FormControlLabel control={<Checkbox onChange={handleOnEnabledChange} checked={selectedControl?.Enabled ?? false} />} label="Enabled" />
                    <Typography variant="subtitle1">Control Behavior:</Typography>
                    <Select
                        fullWidth
                        variant="outlined"
                        size="small"
                        sx={{ width: "200px" }}
                        value={selectedControl?.ControlBehavior || ""} 
                        onChange={handleOnBehaviorChange}>
                        <MenuItem sx={{ padding: '2px 8px' }} value="Visible">Hide</MenuItem>
                        <MenuItem sx={{ padding: '2px 8px' }} value="Enabled">Show</MenuItem>
                    </Select>
                    <Typography variant="subtitle1">Caption:</Typography>
                    <TextField 
                        sx={{ width: "350px" }}
                        onChange={handleOnCaptionChange}
                        fullWidth variant="outlined" size="small"
                        value={selectedControl?.Caption ?? ""} />
                </div>
            </CardContent>
        </Card>    

    );    
}

export default ControlDetails;