import React from "react";
import { ThemeProvider, useTheme, BottomNavigation, BottomNavigationAction } from "@mui/material";
import colorTheme from "../assets/colorTheme";

const Footer : React.FC = () => {
    const theme = useTheme();

    return (
        <ThemeProvider theme={colorTheme}>
            <React.Fragment>
                <BottomNavigation showLabels
                    sx={{
                        position: 'sticky', bottom: 0, width: '100%', justifyContent: "flex-end",
                        '& .MuiBottomNavigationAction-label': {
                            fontSize: "sans-serif",
                            fontWeight: 'bold'
                        },
                    }} >
                    <BottomNavigationAction label={`EPC UI | ${process.env.REACT_APP_ENVIRONMENT ? process.env.REACT_APP_ENVIRONMENT as string : ""}`} />
                </BottomNavigation>
            </React.Fragment>
        </ThemeProvider>
    ); 
};

export default Footer;