
import { IXpathFieldMap } from "../../../models/configuration/xpathFieldMap/IXpathFieldMap";
import { Action, ActionType } from "../../actions";

interface XPathDataState {
  loading: boolean;
  error: string | null;
  data: IXpathFieldMap | null;
}

const initialState = { loading: false, error: null, data: null };

const xPathDataReducer = (
  state: XPathDataState = initialState,
  action: Action
): XPathDataState => {
  switch (action.type) {
    // GET DATA
    case ActionType.GET_CONFIG_XPATH_DATA:
      return { ...state, loading: true, error: null };
    case ActionType.GET_CONFIG_XPATH_DATA_FAILURE:
        return { ...state, loading: false, error: action.payload };
    case ActionType.GET_CONFIG_XPATH_DATA_SUCCESS:
        return { ...state, loading: false, error: null, data: action.payload };

    // SAVE DATA
    case ActionType.SAVE_CONFIG_XPATH_DATA:
      return { ...state, loading: true, error: null };
    case ActionType.SAVE_CONFIG_XPATH_DATA_FAILURE:
        return { ...state, loading: false, error: action.payload };
    case ActionType.SAVE_CONFIG_XPATH_DATA_SUCCESS:
        return { ...state, loading: false, error: null, data: action.payload };

    default:
        return state;
  }
};

export default xPathDataReducer;