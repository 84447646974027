import { SvgIconComponent } from "@mui/icons-material";
import { IDocumentBundle } from "../../../models/configuration/bundles/IBundles";
import { DTDataGridAlignment, DTDataGridColumnType, IDataColumn } from "../../../models/IDataColumn";
import { SelectChangeEvent } from "@mui/material";
import React from "react";

export const unsignedDocColumns = (
  editItem: (e: any, item: any) => void,
  deleteItem: (e: any, item: any) => void
): Array<IDataColumn> => {
  return [
    {
      name: "Edit",
      fieldId: "",
      datatype: DTDataGridColumnType.link,
      linkText: "Edit",
      foreColor: "blue",
      callbackFunction: editItem,
      alignment: DTDataGridAlignment.center
    },
    {
      name: "PackageId",
      fieldId: "PackageId",
      datatype: DTDataGridColumnType.text,
      readonly: true,
      sortable: true
    },
    {
      name: "Bundling Option",
      fieldId: "BundlingOption",
      datatype: DTDataGridColumnType.text,
      sortable: true
    },
    {
      name: "Override Config Exists",
      fieldId: "OverrideConfigExists",
      datatype: DTDataGridColumnType.checkbox,
      alignment: DTDataGridAlignment.center,
      dataFunction: (x: IDocumentBundle) => {
        return x.DocumentHandlingPackageConfigs == null ? false : x.DocumentHandlingPackageConfigs?.length > 0
      }
    },
    {
      name: "Merge List Exists",
      fieldId: "",
      datatype: DTDataGridColumnType.checkbox,
      alignment: DTDataGridAlignment.center,
      dataFunction: (x: IDocumentBundle) => {
        return x.MergerPackageConfigs == null ? false : x.MergerPackageConfigs?.length > 0
      }
    },
    {
      name: "Delete",
      fieldId: "",
      datatype: DTDataGridColumnType.link,
      linkText: "Delete",
      foreColor: "firebrick",
      callbackFunction: deleteItem,
      alignment: DTDataGridAlignment.center
    },
  ];
};

export const signedDocColumns = (
  editItem: (e: any, item: any) => void,
  deleteItem: (e: any, item: any) => void
): Array<IDataColumn> => {
  return [
    {
      name: "Edit",
      fieldId: "",
      datatype: DTDataGridColumnType.link,
      linkText: "Edit",
      foreColor: "blue",
      callbackFunction: editItem,
      alignment: DTDataGridAlignment.center
    },
    {
      name: "PackageId",
      fieldId: "PackageId",
      datatype: DTDataGridColumnType.text,
      sortable: true
    },
    {
      name: "Bundling Option",
      fieldId: "BundlingOption",
      datatype: DTDataGridColumnType.text,
      sortable: true
    },
    {
      name: "Override Config Exists",
      fieldId: "OverrideConfigExists",
      datatype: DTDataGridColumnType.checkbox,
      alignment: DTDataGridAlignment.center,
      dataFunction: (x: IDocumentBundle) => {
        return x.DocumentHandlingPackageConfigs == null ? false : x.DocumentHandlingPackageConfigs?.length > 0
      }
    },
    {
      name: "Merge List Exists",
      fieldId: "",
      datatype: DTDataGridColumnType.checkbox,
      alignment: DTDataGridAlignment.center,
      dataFunction: (x: IDocumentBundle) => {
        return x.MergerPackageConfigs == null ? false : x.MergerPackageConfigs?.length > 0
      }
    },
    {
      name: "Delete",
      fieldId: "",
      datatype: DTDataGridColumnType.link,
      linkText: "Delete",
      foreColor: "firebrick",
      callbackFunction: deleteItem,
    },
  ];
};

export const otherDocColumns = (
  editItem: (e: any, item: any) => void,
  deleteItem: (e: any, item: any) => void
): Array<IDataColumn> => {
  return [
    {
      name: "Edit",
      fieldId: "",
      datatype: DTDataGridColumnType.link,
      linkText: "Edit",
      foreColor: "blue",
      callbackFunction: editItem,
      alignment: DTDataGridAlignment.center
    },
    {
      name: "Event Name",
      fieldId: "EventName",
      datatype: DTDataGridColumnType.text,
      sortable: true
    },
    {
      name: "Delete",
      fieldId: "",
      datatype: DTDataGridColumnType.link,
      linkText: "Delete",
      foreColor: "firebrick",
      callbackFunction: deleteItem,
    },
  ];
};


export const packageConfigColumns = (
  _handleSelectChange: (e: SelectChangeEvent, item: any) => void,
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>, item: any) => void,
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, item: any) => void,
  documentIds: Array<string>
): Array<IDataColumn> => {
  return [
    {
      name: "DocuTech Index",
      fieldId: "DocumentIndex",
      datatype: DTDataGridColumnType.dropdownlist,
      dataOptions: documentIds,
      dataChangeFunction: handleInputChange
    },
    {
      name: "DocuTech Form",
      fieldId: "DocumentName",
      datatype: DTDataGridColumnType.text,
      dataChangeFunction: handleInputChange,
      editOnClick: true,
    },
    {
      name: "Encompass Folder",
      fieldId: "EncompassFolder",
      datatype: DTDataGridColumnType.text,
      dataChangeFunction: handleInputChange,
      editOnClick: true,
    },
    {
      name: "Suffix",
      fieldId: "Suffix",
      datatype: DTDataGridColumnType.text,
      dataChangeFunction: handleInputChange,
      editOnClick: true,
    },
    {
      name: "Reuse Folder",
      fieldId: "ReuseFolder",
      datatype: DTDataGridColumnType.checkbox,
      dataChangeFunction: handleCheckboxChange,
      alignment: DTDataGridAlignment.center,
    },
    {
      name: "Encompass Attachment",
      fieldId: "EncompassAttachment",
      datatype: DTDataGridColumnType.text,
      dataChangeFunction: handleInputChange,
      editOnClick: true,
    },
  ];
};



export const mergePackageConfigColumns = (
  _handleSelectChange: (e: SelectChangeEvent, item: any) => void,
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>, item: any) => void,
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, item: any) => void,
  documentIds: Array<string>
): Array<IDataColumn> => {
  return [
    {
      name: "Merge List",
      fieldId: "MergeList",
      datatype: DTDataGridColumnType.text,
      dataChangeFunction: handleInputChange,
      editOnClick: true
    },
    {
      name: "Encompass Folder",
      fieldId: "EncompassFolder",
      datatype: DTDataGridColumnType.text,
      dataChangeFunction: handleInputChange,
      editOnClick: true
    },
    {
      name: "Reuse Folder",
      fieldId: "ReuseFolder",
      datatype: DTDataGridColumnType.checkbox,
      dataChangeFunction: handleCheckboxChange,
      alignment: DTDataGridAlignment.center
    },
    {
      name: "Encompass Attachment",
      fieldId: "EncompassAttachment",
      datatype: DTDataGridColumnType.text,
      dataChangeFunction: handleInputChange,
      editOnClick: true,
    },
    {
      name: "Suffix",
      fieldId: "Suffix",
      datatype: DTDataGridColumnType.text,
      dataChangeFunction: handleInputChange,
      editOnClick: true,
    },
  ];
};

export const documentMappingColumns = (
  deleteItem: (e: any, item: any) => void,
  lookupItem: (e: any, item: any) => void,
  getForeColor: (item: any) => void,
  getBackColor: (item: any) => void,
  deleteIcon?: SvgIconComponent,
  lookupIcon?: SvgIconComponent,
): Array<IDataColumn> => {
  return [
    {
      name: "Docutech Index",
      fieldId: "DocumentIndex",
      datatype: DTDataGridColumnType.text,
      headerClass: "nowrap",
      sortable: true,
      backColorFunction: getBackColor
    },
    {
      name: "Docutech Form",
      fieldId: "ConformXDocumentName",
      datatype: DTDataGridColumnType.text,
      headerClass: "nowrap",
      sortable: true,
      backColorFunction: getBackColor
    },
    {
      name: "Encompass Document",
      fieldId: "EncompassDocumentName",
      datatype: DTDataGridColumnType.text,
      headerClass: "nowrap",
      sortable: true,
      foreColorFunction: getForeColor,
      backColorFunction: getBackColor
    },
    {
      name: "",
      fieldId: "",
      datatype: DTDataGridColumnType.image,
      alignment: DTDataGridAlignment.center,
      linkText: "Encompass Document",
      foreColor: "#143A6B",
      displayImage: lookupIcon,
      callbackFunction: lookupItem
    },
    {
      name: "",
      fieldId: "",
      datatype: DTDataGridColumnType.image,
      alignment: DTDataGridAlignment.center,
      linkText: "Delete",
      foreColor: "#b22222",
      displayImage: deleteIcon,
      callbackFunction: deleteItem
    },
  ];
};