import { Dispatch } from "redux";
import { Action, ActionType } from "../../actions";
import PluginService from "../../../services/pluginService";
import { IXpathFieldMap } from "../../../models/configuration/xpathFieldMap/IXpathFieldMap";

// This action creator gets the server config values from an api call or state
const getConfigXPathData = (configId: string, token: string | null): (dispatch: Dispatch<Action>, getState: () => any) => Promise<void> => {
    return async (dispatch: Dispatch<Action>, getState: () => any) => {

        dispatch({ type: ActionType.GET_CONFIG_XPATH_DATA });

        try {
            const xPathData = getState().xPathData.data;
            if (xPathData) { // * Cache
                dispatch({
                    type: ActionType.GET_CONFIG_XPATH_DATA_SUCCESS,
                    payload: xPathData
                });
            } else {
                const payload = await new PluginService().getConfig<IXpathFieldMap>(token, "xpath-fieldmap", configId);
                dispatch({
                    type: ActionType.GET_CONFIG_XPATH_DATA_SUCCESS,
                    payload: payload
                });
            }

        } catch (error) {
            console.error("Get Config XPath Config Data");
            const payloadErrorMessage = error instanceof Error
                ? `Failed to fetch Config XPath Config Data: (${error.message})`
                : "Failed to fetch Config XPath Config Data: (Unknown error)";
            dispatch({
                type: ActionType.GET_CONFIG_BUNDLE_DATA_FAILURE,
                payload: payloadErrorMessage,
            });
        }
    }
}

export default getConfigXPathData;