import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { setLoading, setPage } from "../../state/appSlice";
import useBuildAppStart from "../../hooks/useBuildAppStart";
import { useNavigate } from "react-router-dom";

const RefreshButton = () => {
  const dispatch = useDispatch();
  const buildAppStart = useBuildAppStart();
  const navigate = useNavigate();

  const handleAppRefresh = async () => {
    navigate('/')
    dispatch(setPage('/'));
    try {
      dispatch(setLoading(true));
      await buildAppStart(true);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        console.error(error);
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <Button variant="contained" size="small" onClick={handleAppRefresh}>
      Refresh
    </Button>
  );
};

export default RefreshButton;
