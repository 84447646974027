import { DTDataGridAlignment, DTDataGridColumnType, IDataColumn } from "../../models/IDataColumn";
import {IPackage} from "../../models/configuration/plugin/IConfiguration";
import {Tooltip, Typography} from "@mui/material";

// TODO: SUMA, the View Expression link using a dataFunction could be disabled if needed but using the SHowAll button may solve it

export const evaluationResultsColumns = (
    viewExpression: (e: any, item: any) => void,
): Array<IDataColumn> => {
    return [
    {
        name: "Result",
        fieldId: "result",
        datatype: DTDataGridColumnType.checkbox,
        alignment: DTDataGridAlignment.center,
        readonly: true
    },
    {
        name: "Control Type",
        fieldId: "controlType",
        datatype: DTDataGridColumnType.text,
        readonly: true
    },
    {
        name: "Id",
        fieldId: "id",
        datatype: DTDataGridColumnType.text,
        readonly: true
    },
    {
        name: "Group",
        fieldId: "group",
        datatype: DTDataGridColumnType.text,
        readonly: true
    },
    {
        name: "Message",
        fieldId: "message",
        datatype: DTDataGridColumnType.text,
        readonly: true
    },     
    {
        name: "View Expression",
        fieldId: "",
        datatype: DTDataGridColumnType.link,
        linkText: "View Expression",
        foreColor: "blue",
        callbackFunction: viewExpression,
        alignment: DTDataGridAlignment.center, 
    }
    ];
};

export const validDeliveryMethodsColumns = (): Array<IDataColumn> => {
    return [
    {
        name: "Enabled",
        fieldId: "Enabled",
        datatype: DTDataGridColumnType.checkbox,
        alignment: DTDataGridAlignment.center,
        readonly: true
    },
    {
        name: "Group",
        fieldId: "Group",
        datatype: DTDataGridColumnType.text,
        readonly: true,
        sortable: true
    },
    {
        name: "Package",
        fieldId: "Id",
        datatype: DTDataGridColumnType.text,
        readonly: true,
        sortable: true
    },
    {
        name: "Valid Delivery Types",
        fieldId: "ValidDeliveryTypes",
        datatype: DTDataGridColumnType.text,
        readonly: true,
        sortable: true,
        dataFunction: (pkg : IPackage) =>
             {
                return (
                     <Tooltip title={<span style={{ fontSize: "1rem" }}>{pkg.ValidDeliveryTypes.map(String).join(', ')}</span>} arrow>
                     <Typography>{pkg.ValidDeliveryTypes.map(String).join(', ')}</Typography> 
                    </Tooltip>
                )
             }
    }
    ];
};