import React, { useEffect, useState } from 'react';
import xpath from 'xpath';
import { DOMParser } from 'xmldom';
import { Box, Button, Stack, TextField, Snackbar } from '@mui/material';
import { AlertSeverity } from '../../constants/AlertTypes';
import MuiAlert from '@mui/material/Alert';

interface XPathValidatorProps {
    xPath: string,
    resultFxtn: (result: boolean) => void;
    updateXPathFxtn: (xpath: string) => void;
}

const XPathValidator: React.FC<XPathValidatorProps> =
    ({ xPath, resultFxtn, updateXPathFxtn }) => {

        const [xpathState, setXPathState] = useState<string>(xPath);
        const [alertOpen, setAlertOpen] = useState(false);
        const [alertMessage, setAlertMessage] = useState('');
        const [alertSeverity, setAlertSeverity] = useState<AlertSeverity>('info');

        useEffect(() => {
            setXPathState(xPath);
        }, [xPath]);

        const xPathChanged = (xpath: string) => {
            if (xpath && xpath !== "") {
                setXPathState(xpath);
                updateXPathFxtn(xpath);
            }
        }

        const validateXPath = (xpathExpression: string) => {
            try {
                const doc = new DOMParser().parseFromString('<root></root>');
                xpath.select(xpathExpression, doc);
                openAlert('XPath Expression is valid', 'success');
                return true;
            } catch (e) {
                openAlert('XPath Expression is invalid', 'error');
                return false;
            }
        };

        const handleValidate = () => {
            resultFxtn(validateXPath(xpathState));
        };

        // Alert Handling 
        const handleSnackBarClose = (_event: any) => { setAlertOpen(false); }
        const openAlert = (message: string, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
            setAlertSeverity(severity);
            setAlertMessage(message);
            setAlertOpen(true);
        }

        return (
            <>
                <Stack>
                    <Box sx={{ fontWeight: "bold", marginBottom: ".5rem" }}>Input XPath:</Box>
                    <TextField
                        aria-label="minimum height"
                        onChange={(e) => xPathChanged(e.target.value)}
                        multiline
                        minRows={3}
                        value={xpathState}
                        placeholder="Minimum 3 rows"
                        fullWidth
                        variant="outlined"
                    />
                </Stack>
                <Button
                    sx={{ marginTop: 2 }}
                    variant="contained" onClick={handleValidate}>Validate</Button>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={alertOpen}
                    autoHideDuration={4000}
                    onClose={handleSnackBarClose}
                >
                    <MuiAlert
                        onClose={handleSnackBarClose}
                        severity={alertSeverity}
                        sx={{ width: '100%', fontSize: '1.2rem', padding: '12px 16px' }}>
                        {alertMessage}
                    </MuiAlert>
                </Snackbar>
            </>
        );
    };

export default XPathValidator;