import { Action, Dispatch } from "redux";
import { FieldMapping, IXpathFieldMap } from "../../../models/configuration/xpathFieldMap/IXpathFieldMap";
import { ActionType } from "../../actions";
import PluginService from "../../../services/pluginService";

const saveConfigXPathData = (configId: string, FieldMappings: FieldMapping[], token: string | null) => {
    return async (dispatch: Dispatch<Action>) => {

        dispatch({
            type: ActionType.SAVE_CONFIG_XPATH_DATA
        });

        const pluginService = new PluginService();
        try {
            if (!FieldMappings) {
                throw new Error("No FieldMappings to save");
            }

            const requestBody = {
                "ConfigurationId": configId,
                "Configuration": {
                    "FieldMappings": FieldMappings
                }
            };

            await pluginService.saveConfig<IXpathFieldMap>(token, "xpath-fieldmap", configId, requestBody);

            dispatch({
                type: ActionType.SAVE_CONFIG_XPATH_DATA_SUCCESS,
                payload: requestBody
            });

            return Promise.resolve();

        } catch (error) {
            console.log("saveConfigXPathData --> FAILURE");
            if (error instanceof Error) {
                const payloadErrorMessage = `Failed to Save saveConfigXPathData Data: (${error.message})`;
                dispatch({
                    type: ActionType.SAVE_CONFIG_XPATH_DATA_FAILURE,
                    payload: payloadErrorMessage
                });
                throw new Error(payloadErrorMessage);
            }
        }
    }
}

export default saveConfigXPathData;