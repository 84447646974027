import React, { } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface XPathGridItemDisplayDialogProps {
    open: boolean;
    closeFxtn: () => void;
    title?: string | undefined;
    item: string;
}

const XPathGridItemDisplayDialog: React.FC<XPathGridItemDisplayDialogProps> = ({ open, closeFxtn, item, title }) => {

    const [copyNotification, setCopyNotification] = React.useState<string>('');

    const closeDialog = () => {
        closeFxtn();
    }

    const copyText = (e: any, text: string) => {
        navigator.clipboard.writeText(text);
        setCopyNotification('Copied to clipboard');
        setTimeout(function () {
            setCopyNotification('');
        }, 3000);
    }
    return (
        <Dialog open={open} onClose={closeDialog}
            sx={{ width: '100%', height: '100%' }}
        >
            <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                <span>{title ?? "Title"}
                    <IconButton
                        onClick={(e) => { copyText(e, item) }}
                        size="small"
                        sx={{ ml: 1 }}
                    >
                        <ContentCopyIcon fontSize="small" />
                        <h6 style={{ marginLeft: '1rem' }}>{copyNotification}</h6>
                    </IconButton>

                </span>
                <IconButton
                    aria-label="close"
                    onClick={closeDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box> </Box>
                <Box>
                    {item}
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default XPathGridItemDisplayDialog;