import { Box, Button, CircularProgress, Snackbar, Typography } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import React, { useEffect, useState } from "react";
import DTDataGrid from "../shared/config/DTDataGrid";
import { useXPathData } from "../../hooks/useXPathData";
import { xPathDataColumns } from "../../pages/clientData";
import { FieldMapping } from "../../models/configuration/xpathFieldMap/IXpathFieldMap";
import XPathGridItemDisplayDialog from "./XPathGridItemDisplayDialog";
import XPathGridItemEditDialog from "./XPathGridItemEditDialog";
import XPathGridItemAddDialog from "./xPathGridItemAddDialog";
import ConfirmationDialog from "../../components/shared/ConfirmDialog";
import { useActions } from "../../hooks/useActions";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import EncompassService from "../../services/encompassService";
import { AlertSeverity } from "../../constants/AlertTypes";
import "../../assets/gridStyles.css";

const XPathGrid: React.FC = () => {

    const XPathData = useXPathData();
    const { saveConfigXPathData } = useActions();
    const accessToken = useSelector((state: RootState) => state.appSlice.accessToken);
    const [selectedConfigId, setSelectedConfigId] = useState<string>("");
    const [gridData, setGridData] = useState<FieldMapping[]>([]);
    const [selectedItem, setSelectedItem] = useState<FieldMapping | null>(null);
    const [openDisplay, setOpenDisplay] = useState(false);
    const [selectedDisplayItem, setSelectedDisplayItem] = useState<string>('');
    const [openEdit, setOpenEdit] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [saving, setSaving] = useState<boolean>(false);

    // State Confirmations
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState<AlertSeverity>('info');

    useEffect(() => {
        if (XPathData?.Configuration?.FieldMappings) {
            setGridData(XPathData?.Configuration?.FieldMappings);
            const configId = EncompassService.getConfigId() as string;
            setSelectedConfigId(configId);
        }
    }, [XPathData]);

    const handleAdd = () => { setOpenAdd(true); }

    // Delete Handling
    const handleDelete = (e: any, item: FieldMapping) => {
        setSelectedItem(item);
        setOpenDelete(true);
    }
    const cancelDelete = () => { setOpenDelete(false); }
    const deleteItem = async () => {
        let updatedDataGridData;
        if (selectedItem) {
            updatedDataGridData = gridData.filter((fieldMapping: FieldMapping) => fieldMapping.Name !== selectedItem.Name);
            setGridData(updatedDataGridData);
            setSelectedItem(null);
        }
        setOpenDelete(false);
        await save(updatedDataGridData);
    }

    const handleEdit = (e: any, item: any) => {
        if (item) {
            setSelectedItem(item);
            setOpenEdit(true);
        }
    };

    const selectItem = (item: FieldMapping) => {
        setOpenDisplay(true);
        setSelectedDisplayItem(item.Xpath);
    }

    const updateItem = async (item: FieldMapping) => {
        if (selectedItem && item) {
            // Update Grid Items 
            const updatedDataGridData = gridData.map((fieldMapping: FieldMapping) => {
                if (fieldMapping.Name === selectedItem?.Name) {
                    return item;
                }
                return fieldMapping;
            });
            setGridData(updatedDataGridData);
            setSelectedItem(item);
            await save(updatedDataGridData);
        }
    }

    const addItem = async (item: FieldMapping) => {
        if (item) {
            if (gridData.some((fieldMapping: FieldMapping) => fieldMapping.Name === item.Name)) {
                openAlert("Field Mapping with same name / description already exists", "warning");
                return;
            }

            const updatedDataGridData = [...gridData, item];
            setGridData(updatedDataGridData);
            await save(updatedDataGridData);
        }
    }

    const save = async (dataToSave: FieldMapping[] = gridData) => {
        try {
            setSaving(true);
            await saveConfigXPathData(selectedConfigId, dataToSave, accessToken);
            openAlert("Successfully Saved Changes", "success");
        } catch (e) {
            console.error("Error saving data");
        } finally {
            setSaving(false);
        }
    }

    // Alert] Handling 
    const handleSnackBarClose = () => setAlertOpen(false);
    const openAlert = (message: string, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
        setAlertSeverity(severity);
        setAlertMessage(message);
        setAlertOpen(true);
    };


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                padding: 1,
                width: "100%", // take up full width
                height: "100%", // take up full height
                overflow: "hidden", // Prevent Scrolling
            }}>

            <Typography sx={{ color: (theme) => theme.palette.warning.main }} >
                * Double Click any XPath Cell to view full content
            </Typography>

            {/* Button Area */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 1,
                }}>
                <Button
                    variant="contained"
                    onClick={handleAdd}
                    disabled={!XPathData?.Configuration?.FieldMappings || saving}
                >
                    Add
                </Button>
            </Box>

            {/* Data Grid */}
            <Box sx={{
                flex: 1, // take up remaining space 
                overflow: 'hidden'
            }}
            >
                {
                    !XPathData?.Configuration?.FieldMappings || saving ? (
                        <div>
                            <CircularProgress />
                        </div>
                    ) : (
                        <div className="xPathGrid">
                            <DTDataGrid
                                data={gridData}
                                columns={xPathDataColumns(handleEdit, handleDelete)}
                                tableName="xPathDataGrid"
                                onRowDoubleClick={selectItem}
                            />
                        </div>
                    )
                }
            </Box>

            <XPathGridItemDisplayDialog
                open={openDisplay}
                closeFxtn={() => setOpenDisplay(false)}
                item={selectedDisplayItem}
                title="Grid Cell Value (Xpath)"
            />
            <XPathGridItemEditDialog
                open={openEdit}
                closeFxtn={() => setOpenEdit(false)}
                item={selectedItem}
                title="Edit Pushback Field Mapping"
                updateFxtn={updateItem}
            />
            <XPathGridItemAddDialog
                open={openAdd}
                closeFxtn={() => setOpenAdd(false)}
                title="Add Pushback Field Mapping"
                updateFxtn={addItem}
            />
            <ConfirmationDialog
                open={openDelete}
                title="Confirm Yes or No"
                cancelName="No"
                confirmName="Yes"
                message={`Delete selected Field Mappings?`}
                onConfirm={() => deleteItem()}
                onCancel={() => cancelDelete()}
            />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={alertOpen}
                autoHideDuration={2000}
                onClose={handleSnackBarClose}
            >
                <MuiAlert
                    onClose={handleSnackBarClose}
                    severity={alertSeverity}
                    sx={{ width: '100%', fontSize: '1.2rem', padding: '12px 16px' }}>
                    {alertMessage}
                </MuiAlert>
            </Snackbar>
        </Box>
    );
}

export default XPathGrid;