import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, useTheme, IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import { Delete, Search } from "@mui/icons-material";
import DTDataGrid from "../../../../components/shared/config/DTDataGrid";
import { IDocumentMap, IDocumentMappingConfiguration } from "../../../../models/configuration/bundles/IDocumentMappings";
import AddDocumentMapping from "../AddDocumentMapping";
import { useDocumentMappings } from "../../../../hooks/useDocumentMappings";
import DocumentLookup from "./DocumentLookup";
import EncompassService from "../../../../services/encompassService";
import { documentMappingColumns } from "../configbundlingdata";
import { useActions } from "../../../../hooks/useActions";
import { useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import { AlertSeverity } from "../../../../constants/AlertTypes";

interface DocumentMappingDialogProps {
  open: boolean;
  closeFxtn: () => void;
}

const DocumentMappingDialog: React.FC<DocumentMappingDialogProps> = ({ open, closeFxtn }) => {

  const theme = useTheme();
  const { saveDocumentMappings } = useActions();
  const accessToken = useSelector((state: RootState) => state.appSlice.accessToken);
  const [documentMaps, setDocumentMaps] = useState<Array<IDocumentMap> | undefined>([]);
  const [addDocVisible, setAddDocVisible] = useState<boolean>(false);
  const [docLookupOpen, setDocLookupOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState<IDocumentMap>();
  const [addCurrentItem, setAddCurrentItem] = useState(false);
  const [addedEncompassDocName, setAddedEncompassDocName] = useState("");

  // Hooks
  const documentMappings = useDocumentMappings();

  // State Confirmations
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<AlertSeverity>('info');

  useEffect(() => {
    setDocumentMaps(documentMappings?.Configuration.DocumentMaps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDocumentMaps(documentMappings?.Configuration.DocumentMaps);
  }, [documentMappings]);

  const addItem = (docIndex: number, docName: string, encompassDocName: string) => {
    var map: IDocumentMap = {
      DocumentIndex: docIndex.toString(),
      ConformXDocumentName: docName,
      EncompassDocumentName: encompassDocName,
      isnew: true,
    };

    if (documentMaps != null) {
      setDocumentMaps([map, ...documentMaps!]);
    }

    setAddDocVisible(false);
    setAddedEncompassDocName("");
  };

  const deleteItem = (e: any, item: IDocumentMap) => {
    if (documentMaps == null) return;

    var docindex = documentMaps!.indexOf(item);
    if (docindex > -1) {
      setDocumentMaps([
        ...documentMaps
          .slice(0, docindex)
          .concat(documentMaps.slice(docindex + 1)),
      ]);
    }
  };

  const getItemForeColor = (item: IDocumentMap) => {
    if (item.ischanged) {
      return theme.palette.error.dark;
    }
  };
  const getItemBackColor = (item: IDocumentMap) => {
    if (item.isnew) {
      return theme.palette.success.main;
    }
  };

  const showItemLookup = (e: any, item: IDocumentMap) => {
    setCurrentItem(item);
    setDocLookupOpen(true);
  };

  const setItemLookupUpdated = (item: string) => {
    if (addCurrentItem) {
      setAddedEncompassDocName(item);
      setAddCurrentItem(false);
    } else {
      if (currentItem != null && item) {
        currentItem.EncompassDocumentName = item;
        currentItem.ischanged = true;
        setDocumentMaps([...documentMaps!]);
      }
    }

    setDocLookupOpen(false);
  };

  const showAddSelectedItem = () => {
    setAddCurrentItem(true);
    setDocLookupOpen(true);
  };

  const saveChanges = async () => {
    try {
      if (documentMaps == null) return;
      const configId = EncompassService.getConfigId() as string;
      const documentMappingConfig: IDocumentMappingConfiguration = {
        Configuration: {
          DateSaved: formatDate(new Date()),
          DocumentMaps: documentMaps!,
        },
        configurationId: configId,
      };

      await saveDocumentMappings(configId, documentMappingConfig, accessToken);
      await new Promise(resolve => setTimeout(resolve, 2000));
      openAlert("Successfully Saved Changes", "success");
      await new Promise(resolve => setTimeout(resolve, 2000));
      closeFxtn();
    } catch (e) {
      const error = `${e}`;
      openAlert("Successfully Saved Changes", "error");
      console.log("Save Error: ", error);
    }
  }

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const offset = date.getTimezoneOffset();
    const offsetSign = offset < 0 ? "+" : "-";
    const offsetHours = Math.abs(Math.floor(offset / 60))
      .toString()
      .padStart(2, "0");
    const offsetMinutes = Math.abs(offset % 60)
      .toString()
      .padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${offsetSign}${offsetHours}:${offsetMinutes}`;
  };

  if (documentMappings == null) {
    return <></>;
  }

  // Alert Handling 
  const handleSnackBarClose = (_event: any) => { setAlertOpen(false); }
  const openAlert = (message: string, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(true);
  }

  return (
    <>
      <DocumentLookup
        documentList={
          (documentMappings?.Configuration?.DocumentMaps ?? []).map(
            (x) => x.EncompassDocumentName
          ) ?? []
        }
        open={docLookupOpen}
        closeFxtn={() => setDocLookupOpen(false)}
        itemSelected={setItemLookupUpdated}
      />

      <Dialog open={open} onClose={closeFxtn} fullWidth maxWidth="lg">
        <DialogTitle>
          {"Encompass to Docutech Document Configuration"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeFxtn}
          sx={{
                    position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "flex-end", color:theme.palette.error.light}}>
          New Indexes and Docutech Forms are added as Pushback Events are processed
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "column", gap: 2, height: "100%" }}>
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 2, height: "100%" }}
            >
              <Card sx={{
                mt: 1, boxShadow: "0px 0px 3px 0px"
              }} variant="outlined">
                <CardContent>
                  <DTDataGrid
                    columns={documentMappingColumns(
                      deleteItem,
                      showItemLookup,
                      getItemForeColor,
                      getItemBackColor,
                      Delete,
                      Search
                    )}
                    data={documentMaps ?? []}
                    tableName="documentMappings"
                    containerSx={{ maxHeight: "30vh", minHeight: "55vh" }}
                    dense={true}
                    paged={true}
                  />
                </CardContent>
              </Card>
            </Box>

            {addDocVisible ? (
              <AddDocumentMapping
                open={true}
                addItemFunction={addItem}
                showSelectItem={showAddSelectedItem}
                encompassDocName={addedEncompassDocName}
              />
            ) : (
              <Box className={["flexLeft", "height100"].join(" ")}>
                <Button onClick={() => setAddDocVisible(true)}>New</Button>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={saveChanges}>{"Save"}</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alertOpen}
        autoHideDuration={4000}
        onClose={handleSnackBarClose}
      >
        <MuiAlert
          onClose={handleSnackBarClose}
          severity={alertSeverity}
          sx={{ width: '100%', fontSize: '1.2rem', padding: '12px 16px' }}>
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default DocumentMappingDialog;